import React from "react";

const GroupCounter = (props) => {
  const groupCounter = props.row.original.groupCounter;

  return (
    <div style={{ display: "flex" }}>
      {groupCounter.win7 !== 0 ? (
        <div className="dashboard__table__groupCounter" style={{ float: "left" }}>
          <p>win7</p>
        </div>
      ) : null}
      {groupCounter.win10 !== 0 ? (
        <div
          className="dashboard__table__groupCounter"
          style={groupCounter.win7 !== 0 ? { marginLeft: "10px" } : null}>
          <p>win10</p>
        </div>
      ) : null}
      {groupCounter.win11 !== 0 ? (
        <div
          className="dashboard__table__groupCounter"
          style={
            groupCounter.win7 !== 0 || groupCounter.win10 !== 0 ? { marginLeft: "10px" } : null
          }>
          <p>win11</p>
        </div>
      ) : null}
      {groupCounter.linux22 !== 0 ? (
        <div
          className="dashboard__table__groupCounter"
          style={
            groupCounter.win7 !== 0 || groupCounter.win10 !== 0 || groupCounter.win11 !== 0
              ? { marginLeft: "10px" }
              : null
          }>
          <p>linux</p>
        </div>
      ) : null}
    </div>
  );
};

export default GroupCounter;

export const GroupCounter2 = (props) => {
  const groupCounter = props.row.original.groupCounter;
  const isStatic = props.row.original.isStatic;

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {isStatic ? (
        <div className="dashboard__table__groupCounter" style={{ float: "left" }}>
          <p>static</p>
        </div>
      ) : null}
      {groupCounter.includes("win7") ? (
        <div className="dashboard__table__groupCounter" style={{ float: "left" }}>
          <p>win7</p>
        </div>
      ) : null}
      {groupCounter.includes("win10") ? (
        <div className="dashboard__table__groupCounter">
          <p>win10</p>
        </div>
      ) : null}
      {groupCounter.includes("win11") ? (
        <div className="dashboard__table__groupCounter">
          <p>win11</p>
        </div>
      ) : null}
      {groupCounter.includes("linux22") ? (
        <div className="dashboard__table__groupCounter">
          <p>linux</p>
        </div>
      ) : null}
    </div>
  );
};
