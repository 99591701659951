import React, { useState } from "react";
import { useDispatch } from "react-redux";

import TrashBucketIcon from "../../../Icon-SVG/TrashBucketIcon";
import { RequestDeleteConfig } from "../../../../store/actions/integrationsActions";

const TrashButton = ({ intname, mountPointData }) => {
  const dispatch = useDispatch();

  const [deleteHover, setDeleteHover] = useState(false);

  const trashButtonOnClickHandler = (event) => {
    event.stopPropagation();

    dispatch(
      RequestDeleteConfig({
        inttype: intname,
        mountpoint: mountPointData,
      }),
    );
  };

  return (
    <div onClick={trashButtonOnClickHandler}>
      <span
        title="Delete"
        onMouseOver={() => setDeleteHover(true)}
        onMouseOut={() => setDeleteHover(false)}>
        <TrashBucketIcon width={18} height={18} color={deleteHover ? "white" : "red"} />
      </span>
    </div>
  );
};

export default TrashButton;
