import Backdrop from "../../../UI/Backdrop/Backdrop";

const PreStaticResultsTableDetails = ({ isLoading, state, details }) => {
  if (
    !details ||
    !Object.hasOwn(details, "decision") ||
    details["decision"] === 100 ||
    details["decision"] === 99
  ) {
    if (isLoading) {
      return (
        <div className="pre-static-table">
          <div className="pre-static-table__header-wrapper">
            <Backdrop absolute={true} color="transparent" show={isLoading} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="pre-static-table">
          <div className="pre-static-table__header-wrapper">
            <p>No Data Found.</p>
          </div>
        </div>
      );
    }
  }

  if (state.error) {
    return (
      <div className="pre-static-table">
        <div className="pre-static-table__header-wrapper">
          <p>Something Went Wrong, Please Try Again Later.</p>
        </div>
      </div>
    );
  }

  if (!Object.hasOwn(details, "details") || Object.keys(details.details).length === 0) {
    return (
      <div className="pre-static-table">
        <div className="pre-static-table__header-wrapper">
          <p>No Data Found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pre-static-table">
      <div className="pre-static-table__header-wrapper">
        <div className="pre-static-table__header-wrapper__header">
          <h2>Static Results</h2>
          <span
            className={`${
              details.decision === 2
                ? "pre-static-table__malicious"
                : details.decision === 0
                ? "pre-static-table__clean"
                : ""
            } pre-static-table__header-wrapper__header__verdict`}>
            {details.decision === 2
              ? details.classification
                ? `#${
                    details.classification[0].charAt(0).toUpperCase() +
                    details.classification[0].slice(1)
                  }`
                : "#Malware"
              : details.decision === 0
              ? "Not Suspicious"
              : "N/A"}
          </span>
        </div>
        {details.decision === 2 && details.classification && details.classification.length > 1 ? (
          <div className="pre-static-table__header-wrapper__header">
            <h2>Classification</h2>
            <span className="pre-static-table__header-wrapper__header__classification">
              {details.classification
                .slice(1, 4)
                .map((className) => `#${className}`)
                .join(", ")}
            </span>
          </div>
        ) : null}
      </div>
      <div className="pre-static-table__container">
        {Object.keys(details.details)
          .sort()
          .map((key, i) => (
            <div key={key} className="pre-static-table__container__line">
              <div className="pre-static-table__container__line__header">{key}</div>
              <div className="pre-static-table__container__line__data">
                {details.details[key] ? (
                  details.details[key] === "Clean" ||
                  details.details[key] === "0" ||
                  details.details[key] === "None" ||
                  details.details[key].startsWith("0/") ? (
                    <span className="pre-static-table__clean">{details.details[key]}</span>
                  ) : details.details[key] === "N/A" ? (
                    <span className="pre-static-table__none">{details.details[key]}</span>
                  ) : (
                    <span className="pre-static-table__malicious">{details.details[key]}</span>
                  )
                ) : (
                  "-"
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PreStaticResultsTableDetails;
