import React, { useEffect, useState } from "react";
import CustomTable from "../../Components/common/Table/CustomTable";
import { dashboardHeader } from "../../utils/constants/tableHeaders";
import { useLocation } from "react-router-dom";
import TabButton from "../../Components/common/TabButton/TabButton";
import Footer from "../../Components/UI/Footer/Footer";
import ListPopup from "../../Components/PagesComponents/Dashboard/ListPopup/ListPopup";
import "./styles.scss";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const RunningAnalysis = () => {
  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();
  const [listPopupActive, setListPopupActive] = useState(false);

  useEffect(() => {
    if (query.get("data") !== null) {
      setListPopupActive(true);
    } else {
      setListPopupActive(false);
    }
  }, [query]);
  return (
    <div>
      <div className="running-analysis">
        <div className="running-analysis__header">
          <h1>Historical Results</h1>
          <p>Below list is displaying historical results:</p>
        </div>
        <div className="running-analysis__body">
          <div
            className="dashboard__table border"
            style={{ width: "98%", minHeight: "763px", marginBottom: "50px" }}>
            <div className="button-block">
              <TabButton title="Historical Results" />
            </div>
            <CustomTable
              exportFile={true}
              column={dashboardHeader}
              tableName={"latest"}
              tableName2={"historical"}
              leftAction={false}
              isSetUrl
              paginationButtons={true}
              className=""
              minBottom="-43px"
              customPageSize={10}
              minHeightRow="72px"
              paginationHeight="36px"
              paginationBackColor="var(--card-background-color)"
              paginationBorder={true}
              manualPagination={true}
            />
          </div>
        </div>
      </div>
      <ListPopup
        onClickOk={() => setListPopupActive(false)}
        show={listPopupActive}
        groupID={query.get("data")}
        tableType={query.get("table")}
        tableType2="historical"
      />
    </div>
  );
};

export default RunningAnalysis;
