import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  getDateOfISOWeek,
  isCompressedItself,
  isNotRelatedToCompressed,
  selectGroupApt,
} from "../../../../utils/function";
import { getDayDifference, prepareData, severitySelect } from "../utils/statisticsUtil";

const countMaliciousData = (list) => {
  return list.map((columnData) => {
    let maliciousCounter = 0;
    columnData.files.forEach(
      (file) =>
        file.decision === 2 &&
        (isNotRelatedToCompressed(file.compressedID) ||
          isCompressedItself(file.compressedID, file.compressedIDfull, file.fileID)) &&
        maliciousCounter++,
    );

    return maliciousCounter;
  });
};

const countSuspiciousData = (list) => {
  return list.map((columnData) => {
    let suspiciousCounter = 0;
    columnData.files.forEach(
      (file) =>
        file.decision === 1 &&
        (isNotRelatedToCompressed(file.compressedID) ||
          isCompressedItself(file.compressedID, file.compressedIDfull, file.fileID)) &&
        suspiciousCounter++,
    );

    return suspiciousCounter;
  });
};

const countCleanData = (list) => {
  return list.map((columnData) => {
    let cleanCounter = 0;
    columnData.files.forEach(
      (file) =>
        file.decision === 0 &&
        (isNotRelatedToCompressed(file.compressedID) ||
          isCompressedItself(file.compressedID, file.compressedIDfull, file.fileID)) &&
        cleanCounter++,
    );

    return cleanCounter;
  });
};

export const StackedChart = ({ data, setSelectedDataFiles, filteringType, startDate, endDate }) => {
  const [series, setSeries] = useState([
    {
      name: "Malicious",
      data: data ? countMaliciousData(data) : [],
    },
    {
      name: "Suspicious",
      data: data ? countSuspiciousData(data) : [],
    },
    {
      name: "Clean",
      data: data ? countCleanData(data) : [],
    },
  ]);

  const [options, setOptions] = useState({
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: data.length ? true : false,
        },
      },
    },
    chart: {
      type: "bar",
      stacked: true,
      foreColor: "var(--header-search-text-color)",
      parentHeightOffset: 0,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      type: "datetime",
      categories: [],
      labels: {
        format: "dd MMM",
        showDuplicates: false,
      },
    },
    legend: {
      position: "bottom",
      offsetX: -10,
      offsetY: 10,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#8b0000", "#F39C12", "#27AE60"],
    noData: {
      text: "No Data Found",
    },
  });

  useEffect(() => {
    let tempData = [];

    if (data && startDate && endDate) {
      tempData = prepareData(data, startDate, endDate, filteringType);
    }

    setOptions((options) => {
      return {
        ...options,
        chart: {
          ...options.chart,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const files = tempData[config.dataPointIndex].files.reduce((filteredFiles, file) => {
                if (
                  (config.w.config.series[config.seriesIndex].name === "Clean" &&
                    file.decision === 0) ||
                  (config.w.config.series[config.seriesIndex].name === "Suspicious" &&
                    file.decision === 1) ||
                  (config.w.config.series[config.seriesIndex].name === "Malicious" &&
                    file.decision === 2)
                ) {
                  filteredFiles.push({
                    id: file.fileID,
                    fileID: file.fileID,
                    user: file.user,
                    time: file.run_time,
                    file: {
                      name: file.filename,
                      hash: file.fileHash,
                      type: file.fileType,
                      url: "/statistics?data=" + file.groupID,
                    },
                    oS: file.os,
                    progress: file.status,
                    decision: file.decision,
                    severity: severitySelect(file.decision),
                    groupID: file.groupID,
                    compressedID: file.compressedID,
                    compressedIDfull: file.compressedIDfull,
                    fileCounter: file.fileCounter,
                    parentID: file.parentID,
                    hashIcon: file.fileType,
                    submitType: file.submitType,
                    is_apt: file.is_apt,
                    apt_classes: file.apt_classes,
                  });
                }

                return filteredFiles;
              }, []);

              setSelectedDataFiles(files);
            },
          },
        },
        plotOptions: {
          ...options.plotOptions,
          bar: {
            ...options.plotOptions.bar,
            columnWidth:
              filteringType === "monthly"
                ? "45%"
                : filteringType === "daily" && getDayDifference(startDate, endDate) === 1
                ? "15%"
                : filteringType === "daily" && getDayDifference(startDate, endDate) === 2
                ? "30%"
                : filteringType === "daily" && getDayDifference(startDate, endDate) === 3
                ? "40%"
                : filteringType === "daily" && getDayDifference(startDate, endDate) <= 5
                ? "50%"
                : filteringType === "daily" && getDayDifference(startDate, endDate) <= 7
                ? "55%"
                : "70%",
          },
        },
        xaxis: {
          ...options.xaxis,
          categories: !tempData
            ? []
            : filteringType === "daily"
            ? tempData.map((columnData) => columnData._id)
            : filteringType === "weekly"
            ? tempData.map((columnData) => {
                const date = getDateOfISOWeek(columnData._id, columnData.year);

                var month = date.getMonth() + 1; //months from 1-12
                var day = date.getDate();
                var year = date.getFullYear();

                return `${year}-${month}-${day}`;
              })
            : filteringType === "monthly"
            ? tempData.map((columnData) => `${columnData._id.year}-${columnData._id.month}-1`)
            : [],
          labels: {
            ...options.xaxis.labels,
            format:
              filteringType === "daily"
                ? "dd MMM"
                : filteringType === "weekly" || filteringType === "monthly"
                ? "MMM yyyy"
                : "dd MMM",
          },
        },
        tooltip: {
          ...options.tooltip,
          x: {
            show: true,
            format:
              filteringType === "daily" || filteringType === "weekly"
                ? "d MMM yyyy"
                : filteringType === "monthly"
                ? "MMM yyyy"
                : "dd MMM",
          },
        },
      };
    });

    setSeries([
      {
        name: "Malicious",
        data: tempData ? countMaliciousData(tempData) : [],
      },
      {
        name: "Suspicious",
        data: tempData ? countSuspiciousData(tempData) : [],
      },
      {
        name: "Clean",
        data: tempData ? countCleanData(tempData) : [],
      },
    ]);
  }, [data]);

  return (
    <div style={{ width: "100%" }}>
      <Chart options={options} series={series} type="bar" height={520} width={"100%"} />;
    </div>
  );
};
