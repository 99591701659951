import "./styles.scss";

const imapemail = {
  header: "Imap Email",
  longDescription:
    "Imap Email integration will check incoming emails to analyse attachments which belongs to added mail box in the settings. IMAP is an email protocol via which your email account is configured in CyberFortress SBOX. It provides various benefits, the best of which is that it enables attachment analysis for any APT attack. An IMAP email credential is enough to enable this integration in any email platfrom. CyberFortress SBOX will alert admin and user when any malicious attachment found in that mail box. It also allow to create spam/phishing analysis use-case which is reported from other users also.",
  inputList: [
    {
      label: "Authority",
      value: "https://login.microsoftonline.com/fa6130f9-1aab-4181-a73c-073111351c17",
    },
    {
      label: "Client Id",
      value: "12345678-XXXX-XXXX-XXXX-XXXXXXXXXXXX",
    },
    {
      label: "Secret",
      value: "1234567890ABCD-XXXXXXXXXXXXXXXXXXXXXXXXX",
    },
    {
      label: "Secret Id",
      value: "12345678-XXXX-XXXX-XXXX-XXXXXXXXXXXX",
    },
    {
      label: "Username",
      value: "example@example.com",
    },
    {
      label: "Password",
      value: "ExamplePassword123!",
    },
    {
      label: "Server",
      value: "outlook.office365.com",
    },
    {
      label: "Scope",
      value: "https://outlook.office365.com/.default",
    },
  ],
  prerequisitesList: [
    "This instance needs to have network access to added imap accounts.",
    "Username and password needs to be valid to have correct authentication to mailbox.",
  ],
};

const postfixemail = {
  header: "Postfix Email",
  longDescription:
    "The Postfix Email integration collaborates with CyberFortress SBOX to ensure the secure transmission of email messages via SMTP. This integration oversees the transmission processes of Postfix, an email server software, identifying potential security risks and providing a secure communication environment. CyberFortress SBOX allows for the technical monitoring of email messages, helps identify security vulnerabilities, and contributes preventively against cyber attacks.",
  inputList: [
    {
      label: "Server",
      value: "smtp.office365.com",
    },
    {
      label: "Port",
      value: "587",
    },
    {
      label: "Username",
      value: "example@example.com",
    },
    {
      label: "Password",
      value: "ExamplePassword123!",
    },
    {
      label: "Company",
      value: "Example Company",
    },
    {
      label: "Short",
      value: "Malicious Email Detected !",
    },
    {
      label: "Cirt Email",
      value: "example@example.com",
    },
  ],
  prerequisitesList: [
    "This integration requires network access to the added Postfix Email accounts.",
    "Valid username and password are necessary for correct authentication.",
  ],
};

const sharepoint = {
  header: "Sharepoint",
  longDescription:
    "The Sharepoint integration or other cloud-based storage platforms integrate with CyberFortress SBOX to enhance the security of file sharing in cloud-based environments. This integration oversees file transfer processes in cloud-based platforms, identifying potential risks and ensuring security. CyberFortress SBOX allows for the technical oversight of file sharing in cloud-based platforms, helps identify security vulnerabilities, and contributes significantly to secure file sharing.",
  inputList: [
    {
      label: "Authority",
      value: "https://login.microsoftonline.com/fa6130f9-1aab-4181-a73c-073111351c17",
    },
    {
      label: "Client Id",
      value: "12345678-XXXX-XXXX-XXXX-XXXXXXXXXXXX",
    },
    {
      label: "Secret",
      value: "1234567890ABCD-XXXXXXXXXXXXXXXXXXXXXXXXX",
    },
    {
      label: "Secret Id",
      value: "12345678-XXXX-XXXX-XXXX-XXXXXXXXXXXX",
    },
    {
      label: "Drive Id",
      value: "1234567890-XXXXXXXXXXXXXXXXXXXXXXXXX",
    },
    {
      label: "Server",
      value: "documentLibrary",
    },
    {
      label: "Type",
      value: "onedrive",
    },
  ],
  prerequisitesList: [
    "This integration should have network access to SharePoint.",
    "Validly authenticated SharePoint accounts requiring authorized access.",
  ],
};

const fileshare = {
  header: "File Share",
  longDescription:
    "The File Share integration enhances the security of data used in file sharing by analyzing it through CyberFortress SBOX. This integration monitors data exchanges in file sharing processes, identifying potential risks and ensuring security. CyberFortress SBOX technically oversees file sharing, assists in identifying security vulnerabilities, and provides a secure environment for sharing files.",
  inputList: [
    {
      label: "Username*",
      value: "exampleusername",
    },
    {
      label: "Password*",
      value: "ExamplePassword123!",
    },
    {
      label: "Type",
      value: "CIFS",
    },
    {
      label: "IP*",
      value: "192.168.1.1",
    },
    {
      label: "Path*",
      value: "/file/path",
    },
    {
      label: "Port",
      value: "445",
    },
  ],
  prerequisitesList: [
    "This integration should have access to an available and accessible file sharing service on the network.",
    "The Username, Password, File Share Type, Server Ip, Folder Path must be valid for proper authentication to the file sharing accounts.",
  ],
};

const proxy = {
  header: "Proxy",
  longDescription:
    "The ICAP Proxy integration helps in detecting potential threats within inbound and outbound data by analyzing them through CyberFortress SBOX. By scrutinizing files, content, and data flow during transmission, it identifies malicious software, harmful links, or other security threats, providing an early warning system against cyber attacks. CyberFortress SBOX technically monitors data traffic and offers a proactive security measure against cyber threats. The ICAP Proxy integration is an effective security solution for identifying vulnerabilities and taking preventive measures against cyber threats.",
  inputList: [],
  prerequisitesList: [
    "This integration should have access to a server where ICAP Proxy is active on the network.",
  ],
};

const endpoint = {
  header: "CrowdStrike",
  longDescription:
    "The EDR API integration enhances the security of files flagged by endpoint detection and response systems by automatically analyzing those that are identified as malicious or suspicious. This integration is designed to work seamlessly with various EDR platforms, enabling CyberFortress SBOX to receive and analyze files that are suspected of being threats.",
  inputList: [],
  prerequisitesList: [],
};

const defender = {
  header: "Defender",
  longDescription:
    "The Windows Defender integration enhances the security of endpoint devices by analyzing files that Windows Defender flags as potentially malicious. Once flagged by Windows Defender, these files are automatically sent to CyberFortress SBOX for thorough analysis.",
  inputList: [],
  prerequisitesList: [],
};

const IntegrationModalContent = ({ modalType }) => {
  if (modalType === "imapemail") {
    return <ModalContent type={imapemail} />;
  } else if (modalType === "postfixemail") {
    return <ModalContent type={postfixemail} />;
  } else if (modalType === "rclshare") {
    return <ModalContent type={sharepoint} />;
  } else if (modalType === "winshare") {
    return <ModalContent type={fileshare} />;
  } else if (modalType === "icapweb") {
    return <ModalContent type={proxy} />;
  } else if (modalType === "cstrike") {
    return <ModalContent type={endpoint} />;
  } else if (modalType === "defender") {
    return <ModalContent type={defender} />;
  } else if (modalType === "usbtransfer") {
    return <ModalContent />;
  }
};

export default IntegrationModalContent;

const ModalContent = ({ type }) => {
  const { header, longDescription, inputList, prerequisitesList } = type;
  return (
    <div className="content-container">
      <h3 className="content-container__header">{header}</h3>
      <p className="content-container__text">{longDescription}</p>
      {inputList.length > 0 ? (
        <>
          <h3 className="content-container__header">Example Data</h3>
          <div className="content-container__inputs">
            {inputList.map((input, i) => (
              <InputGroup key={i} label={input.label} value={input.value} />
            ))}
          </div>
        </>
      ) : null}
      <h3 className="content-container__header">Prerequisites</h3>
      {prerequisitesList.map((prereq, i) => (
        <p key={i} className="content-container__list-item">
          {prereq}
        </p>
      ))}
    </div>
  );
};

const InputGroup = ({ label, value }) => {
  return (
    <div className="integration-modal__input-group">
      <label className="integration-modal__label">{label}</label>
      <input className="integration-modal__input" value={value} readOnly />
    </div>
  );
};
