const IocsSidebar = ({ menuItems, activeMenu, onMenuItemSelect }) => {
  return (
    <div className="iocs__sidebar">
      {menuItems.map((menuItem) => (
        <button
          className={`iocs__sidebar__menu-item ${
            activeMenu.id === menuItem.id ? "iocs__sidebar__menu-item--active" : ""
          }`}
          key={menuItem.id}
          onClick={() => onMenuItemSelect(menuItem)}>
          {menuItem.header}
          <span>({menuItem.count})</span>
        </button>
      ))}
    </div>
  );
};

export default IocsSidebar;
