import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RequestGetPreStaticResults } from "../../../../../store/actions/analysisReportActions";

const StaticResultsRunning = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const progress = props?.row?.original.progress;
  const fileID = props?.row?.original?.id;
  const staticResult = props?.row?.original?.staticResult || {};
  const staticResultLink = props.row.original.staticResultLink;

  const staticResultRequestState =
    useSelector((state) => state?.analysisReports?.preStaticResults[fileID]) || {};

  useEffect(() => {
    if (
      ["Running", "Reporting", "Completed"].includes(progress) &&
      (!staticResult ||
        !Object.hasOwn(staticResult, "decision") ||
        [99, 100].includes(staticResult["decision"])) &&
      fileID
    ) {
      dispatch(RequestGetPreStaticResults({ id: fileID, type: "group" }));
    }
  }, [fileID, staticResult["decision"], progress]);

  const staticResultsOnClickHandler = (event) => {
    event.stopPropagation();

    if (!progress || progress === "In Queue") {
      return;
    }

    history.push(staticResultLink);
  };

  return (
    <div className={`dashboard__table__staticResultsRunning`}>
      <button
        className={`severity-status__check ${
          !progress || progress === "In Queue"
            ? "severity-status__check--disabled"
            : !staticResult || !Object.hasOwn(staticResult, "decision")
            ? ""
            : staticResult.decision === 2
            ? "dashboard__table__staticResultsRunning__malicious"
            : staticResult.decision === 0
            ? "dashboard__table__staticResultsRunning__clean"
            : ""
        }`}
        onClick={staticResultsOnClickHandler}>
        Static
      </button>
    </div>
  );
};

export default StaticResultsRunning;
