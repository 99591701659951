const IntegrationConfigListHeader = ({ columns, trash }) => {
  return (
    <table className="integration-configTable-header">
      <thead>
        <tr>
          {columns.map((column, i) => (
            <td key={column.title || i} style={{ width: column.width ? column.width : "30%" }}>
              {column.title ? column.title : ""}
            </td>
          ))}
          {trash && trash.enabled ? (
            <td
              key="delete"
              style={{
                width: trash && trash.enabled ? (trash.width ? trash.width : "10%") : "",
              }}></td>
          ) : null}
        </tr>
      </thead>
    </table>
  );
};
export default IntegrationConfigListHeader;
