import { useEffect, useState } from "react";

import ExpandOrShrinkIcon from "../../../../UI/DropDown/ExpandOrShrinkIcon";

import "./styles.scss";

const checkIfMathcesAny = (file) => {
  if (file?.clamav?.length > 0) return true;
  if (file?.yara?.length > 0) return true;
  if (file?.cdecision?.malicious) return true;
  if (file?.mlcheck?.malicious) return true;
  return false;
};

const TypeCard = ({
  className = "",
  value,
  selectedFile,
  setSelectedFile,
  title,
  active,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (selectedFile && title.toLowerCase().includes(selectedFile.type.toLowerCase())) {
      setIsOpen(true);
    }
  }, [selectedFile, title]);

  const clickHandler = () => {
    setIsOpen((prevValue) => !prevValue);
    onClick(title);
  };

  return (
    <>
      <div className={`dropped-files__sidebar__type-card ${className}`} onClick={clickHandler}>
        {title}({value.length}) <ExpandOrShrinkIcon expanded={isOpen} collapsed={false} />
      </div>
      {isOpen && value.length ? (
        <div className="dropped-files__sidebar__type-card__files">
          {value.map((file) => (
            <div
              className={`dropped-files__sidebar__type-card__file ${
                checkIfMathcesAny(file)
                  ? "dropped-files__sidebar__type-card__file--matched"
                  : "dropped-files__sidebar__type-card__file--unmatched"
              }`}
              // ${
              //   selectedFile?.id === file.id
              //     ? "dropped-files__sidebar__type-card__file--active"
              //     : ""
              // }`
              // }
              key={file.id}
              onClick={() => setSelectedFile(file)}>
              <p className="dropped-files__sidebar__type-card__file__name">{file.value}</p>
              {selectedFile?.id === file.id ? (
                <div className="dropped-files__sidebar__type-card__file__active-circle"></div>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default TypeCard;
