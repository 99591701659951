import React from "react";
import "./styles.scss";
import LogoIcon from "../../Icon-SVG/LogoIcon";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__inner">
        {/* <p className="footer__inner__text">Copyrights © 2024 -</p> */}
        <a
          className="footer__inner__link"
          href="https://cyber-fortress.com/"
          target="_blank"
          rel="noreferrer">
          <div className="footer__logo">
            <div className="footer__logo__icon">
              <LogoIcon width={23} />
            </div>
            <div className="footer__logo__text">
              <strong className="footer__logo__text-cyber">Cyber</strong>
              <strong className="footer__logo__text-fortress">Fortress</strong>
            </div>
          </div>
        </a>
        <span className="footer__inner__pipe"> | </span>
        <p className="footer__inner__text">Security Professionals</p>
      </div>
    </div>
  );
};

export default Footer;
