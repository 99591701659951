import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loading: false,
    submissionsListLoading: false,
    submittedFile: { state: false, from: null },
    error: null,
    dashboard: [],
    list: [],
    runningList: [],
    pageCount: 0,
    latestNotComplete: [],
    malicious: [],
    zipfiles: [],
    cleanState: 0,
    cleanCount: 0,
    suspiciousState: 0,
    suspiciousCount: 0,
    maliciousState: 0,
    maliciousCount: 0,
    totalSubmission: 0,
    dailyCounter: 0,
    dailyAptCounter: 0,
    groupVerdict: null,
    submissionsLoading: false,
    runningListLoading: false,
    currentFile: null,
    freerunFileID: null,
    submitFreerunLoading: false,
    submitFreerunError: null,
    urlError: null,
    urlLoading: false,
    uiLogo: "",
    loadingLogo: false,
    submissionsListForStatsLoading: false,
    submissionsListForStats: [],
    runningCount: 0,
    searchResults: [],
    searchResultsLoading: false,
    integrationSubmissionCount: 0,
    dailyIntegrationSubmissionCount: 0,
    integrationCleanState: 0,
    integrationCleanCount: 0,
    integrationSuspiciousState: 0,
    integrationSuspiciousCount: 0,
    integrationMaliciousState: 0,
    integrationMaliciousCount: 0,
    groupSubmissionsLoading: false,
    groupSubmissions: [],
    vms: [],
    aptCount: 0,
    malwareNameCounts: [],
    integrationMalwareNameCounts: [],
    uniqueSubmissionsCount: 0,
  },
  reducers: {
    RequestDashboard: (state) => {
      state.loading = true;
    },
    SuccessDashboard: (state, payload) => {
      state.loading = false;
      state.dashboard = payload.payload;
    },
    FailureDashboard: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestNewFile: (state) => {
      state.loading = true;
      state.submissionsLoading = true;
    },
    SuccessNewFile: (state) => {
      state.loading = false;
      state.submissionsLoading = false;
    },
    FailureNewFile: (state) => {
      state.loading = false;
      state.submissionsLoading = false;
    },
    RequestQuickSubmit: (state) => {
      state.submissionsLoading = true;
    },
    SuccessQuickSubmit: (state) => {
      state.submissionsLoading = false;
    },
    FailureQuickSubmit: (state) => {
      state.submissionsLoading = false;
    },
    RequestStaticSubmit: (state) => {
      state.submissionsLoading = true;
    },
    SuccessStaticSubmit: (state) => {
      state.submissionsLoading = false;
    },
    FailureStaticSubmit: (state) => {
      state.submissionsLoading = false;
    },
    CurrentNewFile: (state, payload) => {
      state.currentFile = payload?.payload;
    },
    RequestNewScript: (state) => {
      state.loading = true;
    },
    SuccessNewScript: (state) => {
      state.loading = false;
    },
    FailureNewScript: (state) => {
      state.loading = false;
    },
    RequestDailySubmissions: (state) => {},
    SuccessDailySubmissions: (state, action) => {
      const { daily_submission_count, daily_apt_count } = action.payload;
      state.dailyCounter = daily_submission_count;
      state.dailyAptCounter = daily_apt_count;
    },
    FailureDailySubmissions: (state) => {},
    RequestSubmissionsList: (state) => {
      state.submissionsListLoading = true;
    },
    SuccessSubmissionsList: (state, action) => {
      const { list, page_count } = action.payload;
      state.submissionsListLoading = false;
      state.list = list;
      state.pageCount = page_count;
    },
    FailureSubmissionsList: (state, payload) => {
      state.submissionsListLoading = false;
      state.error = payload?.payload;
    },
    RequestAllSubmissions: (state) => {},
    SuccessAllSubmissions: (state, payload) => {
      state.totalSubmission = payload?.payload;
    },
    FailureAllSubmissions: (state) => {},
    RequestCleanSubmissionsState: (state) => {
      state.loading = true;
    },
    SuccessCleanSubmissionsState: (state, action) => {
      const { clean_count, clean_state } = action.payload;
      state.loading = false;
      state.cleanState = clean_state;
      state.cleanCount = clean_count;
    },
    FailureCleanSubmissionsState: (state) => {
      state.loading = false;
    },
    RequestSuspiciousSubmissionsState: (state) => {
      state.loading = true;
    },
    SuccessSuspiciousSubmissionsState: (state, action) => {
      const { suspicious_count, suspicious_state } = action.payload;
      state.loading = false;
      state.suspiciousState = suspicious_state;
      state.suspiciousCount = suspicious_count;
    },
    FailureSuspiciousSubmissionsState: (state) => {
      state.loading = false;
    },
    RequestMaliciousSubmissionsState: (state) => {
      state.loading = true;
    },
    SuccessMaliciousSubmissionsState: (state, action) => {
      const { malicious_count, malicious_state } = action.payload;
      state.loading = false;
      state.maliciousState = malicious_state;
      state.maliciousCount = malicious_count;
    },
    FailureMaliciousSubmissionsState: (state) => {
      state.loading = false;
    },
    SetSubmittedFile: (state, payload) => {
      state.submittedFile = payload?.payload;
    },
    CopyClipboard: (state) => {
      state.loading = true;
    },
    RequestGroupVerdict: (state) => {
      state.loading = true;
    },
    SuccessGroupVerdict: (state, payload) => {
      state.loading = false;
      state.groupVerdict = payload.payload;
    },
    FailureGroupVerdict: (state, payload) => {
      state.loading = false;
      state.error = payload.payload;
    },
    RequestResubmit: (state, action) => {
      state.currentFile = action.payload.fileName;
      state.loading = true;
      state.submissionsLoading = true;
    },
    SuccessResubmit: (state) => {
      state.loading = false;
      state.submissionsLoading = false;
    },
    FailureResubmit: (state) => {
      state.loading = false;
      state.submissionsLoading = false;
    },
    RequestSubmitFreerun: (state) => {
      state.freerunFileID = null;
      state.submitFreerunError = null;
      state.submitFreerunLoading = true;
    },
    SuccessSubmitFreerun: (state, action) => {
      state.freerunFileID = action.payload;
      state.submitFreerunLoading = false;
    },
    FailureSubmitFreerun: (state, action) => {
      state.submitFreerunError = action.payload;
      state.submitFreerunLoading = false;
    },
    RequestToggleFavorite: () => {},
    RequestCleanRunningTab: () => {},
    RequestNewUrl: (state) => {
      state.urlLoading = true;
      state.urlError = null;
    },
    SuccessNewUrl: (state) => {
      state.urlLoading = false;
      state.urlError = null;
    },
    FailureNewUrl: (state, action) => {
      state.urlLoading = false;
      state.urlError = action.payload;
    },
    ResetUrlError: (state) => {
      state.urlError = null;
    },
    RequestSetUILogo: (state) => {
      state.loadingLogo = true;
    },
    SuccessSetUILogo: (state) => {
      state.loadingLogo = false;
    },
    FailureSetUILogo: (state, action) => {
      state.loadingLogo = false;
      state.error = action.payload;
    },
    RequestGetUILogo: (state) => {
      state.loading = true;
    },
    SuccessGetUILogo: (state, action) => {
      state.uiLogo = action.payload;
    },
    FailureGetUILogo: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    RequestSubmissionsListForStats: (state) => {
      state.submissionsListForStatsLoading = true;
    },
    SuccessSubmissionsListForStats: (state, action) => {
      state.submissionsListForStats = action.payload;
      state.submissionsListForStatsLoading = false;
    },
    FailureSubmissionsListForStats: (state) => {
      state.submissionsListForStatsLoading = false;
    },
    RequestRunningCount: () => {},
    SuccessRunningCount: (state, action) => {
      state.runningCount = action.payload;
    },
    FailureRunningCount: () => {},
    RequestRunningSubmissions: (state) => {
      state.runningListLoading = true;
    },
    SuccessRunningSubmissions: (state, action) => {
      state.runningListLoading = false;
      state.runningList = action?.payload;
    },
    FailureRunningSubmissions: (state) => {
      state.runningListLoading = false;
    },
    RequestSearchCompletedSubmissions: (state) => {
      state.searchResultsLoading = true;
    },
    SuccessSearchCompletedSubmissions: (state, action) => {
      state.searchResultsLoading = false;
      state.searchResults = action.payload;
    },
    FailureSearchCompletedSubmissions: (state) => {
      state.searchResultsLoading = false;
    },
    RequestIntegrationSubmissionCount: () => {},
    SuccessIntegrationSubmissionCount: (state, action) => {
      state.integrationSubmissionCount = action.payload;
    },
    FailureIntegrationSubmissionCount: () => {},
    RequestDailyIntegrationSubmissionCount: () => {},
    SuccessDailyIntegrationSubmissionCount: (state, action) => {
      state.dailyIntegrationSubmissionCount = action.payload;
    },
    FailureDailyIntegrationSubmissionCount: () => {},
    RequestIntegrationSubmissionState: () => {},
    SuccessIntegrationSubmissionState: (state, action) => {
      const {
        clean_state,
        suspicious_state,
        malicious_state,
        clean_count,
        suspicious_count,
        malicious_count,
      } = action.payload;
      state.integrationCleanState = clean_state;
      state.integrationSuspiciousState = suspicious_state;
      state.integrationMaliciousState = malicious_state;
      state.integrationCleanCount = clean_count;
      state.integrationSuspiciousCount = suspicious_count;
      state.integrationMaliciousCount = malicious_count;
    },
    FailureIntegrationSubmissionState: () => {},
    RequestSearchGroupSubmissions: (state) => {
      state.groupSubmissionsLoading = true;
    },
    SuccessSearchGroupSubmissions: (state, action) => {
      state.groupSubmissionsLoading = false;
      state.groupSubmissions = action.payload;
    },
    FailureSearchGroupSubmissions: (state) => {
      state.groupSubmissionsLoading = false;
    },
    RequestVms: (state) => {},
    SuccessVms: (state, action) => {
      state.vms = action.payload;
    },
    FailureVms: (state) => {
      state.vms = [];
    },
    RequestAptCount: () => {},
    SuccessAptCount: (state, action) => {
      state.aptCount = action.payload;
    },
    FailureAptCount: (state) => {
      state.aptCount = 0;
    },
    RequestMalwareNameCounts: () => {},
    SuccessMalwareNameCounts: (state, action) => {
      state.malwareNameCounts = action.payload;
    },
    FailureMalwareNameCounts: () => {},
    RequestIntegrationMalwareNameCounts: () => {},
    SuccessIntegrationMalwareNameCounts: (state, action) => {
      state.integrationMalwareNameCounts = action.payload;
    },
    FailureIntegrationMalwareNameCounts: () => {},
    RequestDeleteSubmissionFromList: () => {},
    RequestResubmitSample: (state) => {
      state.submissionsLoading = true;
    },
    SuccessResubmitSample: (state) => {
      state.submissionsLoading = false;
    },
    FailureResubmitSample: (state) => {
      state.submissionsLoading = false;
    },
    RequestUniqueSubmissionsCount: () => {},
    SuccessUniqueSubmissionsCount: (state, action) => {
      state.uniqueSubmissionsCount = action.payload;
    },
    FailureUniqueSubmissionsCount: () => {},
  },
});

export default dashboardSlice;
