import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";

import CsvIcon from "../Components/Icon-SVG/CSVIcon";
import axiosApi from "./axios";

const manualHeaders = [
  { label: "Username", key: "user_name" },
  { label: "Submit Time", key: "submit_time" },
  { label: "Submit Type", key: "submit_type" },
  { label: "File Name", key: "file_name" },
  { label: "File Hash", key: "file_hash" },
  { label: "Count", key: "count" },
  { label: "Os", key: "os" },
  { label: "Verdict", key: "verdict" },
];

const ExportCSV = ({
  csvData,
  headers,
  fileName,
  selected,
  agentHostname,
  selectedTimeFilter,
  manualPagination = false,
  tableName,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const csvLinkRef = useRef(null);

  let newCsvData = csvData;
  if (selected) {
    newCsvData = newCsvData.map((csv) => csv.original);
  }

  useEffect(() => {
    if (!loading || data.length > 0) {
      return;
    }

    const fetchExportData = async () => {
      setLoading(true);
      let additionalUrlArgs = "";
      if (agentHostname) {
        additionalUrlArgs += `&agentHostName=${agentHostname}`;
      }
      if (selectedTimeFilter) {
        additionalUrlArgs += `&filterByLast=${selectedTimeFilter.id}`;
      }
      try {
        const response = await axiosApi.get(
          `api/dashboard/submission/export-data?tableName=${tableName}${additionalUrlArgs}`,
        );
        setData(response.data);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };

    fetchExportData();
  }, [loading]);

  useEffect(() => {
    if (data.length > 0) {
      csvLinkRef.current.link.click();
    }
  }, [data]);

  if (manualPagination) {
    return (
      <>
        <button
          onClick={() => {
            if (!loading && data.length === 0) {
              setLoading(true);
            }
          }}
          style={{ background: "none", border: "none", outline: "none", cursor: "pointer" }}>
          <CsvIcon />
        </button>
        <CSVLink data={data} headers={manualHeaders} filename={fileName} ref={csvLinkRef}></CSVLink>
      </>
    );
  }

  return (
    <CSVLink data={newCsvData} headers={headers} filename={fileName}>
      <button style={{ background: "none", border: "none", outline: "none", cursor: "pointer" }}>
        <CsvIcon />
      </button>
    </CSVLink>
  );
};

export default ExportCSV;
