import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import IocsContent from "./components/IocsContent";
import IocsSidebar from "./components/IocsSidebar";
import Backdrop from "../../../../../UI/Backdrop/Backdrop";

import { MENU_ITEMS } from "./constants/constants";

import "./styles.scss";

const Iocs = () => {
  const [activeMenu, setActiveMenu] = useState(MENU_ITEMS[0]);
  const [menuItems, setMenuItems] = useState([]);

  const iocs = useSelector((state) => state.analysisReports.iocs);
  const loading = useSelector((state) => state.analysisReports.iocsLoading);

  useEffect(() => {
    if (!iocs || Object.keys(iocs).length === 0) return;
    const mappedMenuItems = MENU_ITEMS.map((menuItem) => ({
      ...menuItem,
      count: iocs[menuItem.id] ? iocs[menuItem.id].length : 0,
    }));
    setMenuItems(mappedMenuItems);
  }, [iocs]);

  const selectMenuItemHandler = (menuItem) => {
    setActiveMenu(menuItem);
  };

  if (loading) {
    return (
      <div className="iocs border">
        <Backdrop show={loading} absolute={true} />
      </div>
    );
  }

  return (
    <div className="iocs border">
      <IocsSidebar
        menuItems={menuItems}
        activeMenu={activeMenu}
        onMenuItemSelect={selectMenuItemHandler}
      />
      <IocsContent activeMenu={activeMenu} iocs={iocs} />
    </div>
  );
};

export default Iocs;
