export const MENU_ITEMS = [
  {
    id: "urls",
    header: "Url Addresses",
    description: "You can find the list of http and https url addresses which accessed by this malware below",
  },
  {
    id: "hosts",
    header: "Domain Names",
    description: "You can find the list of domain names which accessed by this malware below",
  },
  {
    id: "ips",
    header: "Ip Addresses",
    description: "You can find the list of ip addresses which accessed by this malware below",
  },
  {
    id: "hashes",
    header: "Sha256 Hashes",
    description: "You can find the list of Sha256 hash values which seen in artifact lists below",
  },
];
