import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import AnalysisReports from "./AnalysisReports";
import Backdrop from "../../Components/UI/Backdrop/Backdrop";

import { RequestCheckReportExists } from "../../store/actions/analysisReportActions";

import "./styles.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

let firstTimeLoading = true;

const AnalysisReportsWrapper = () => {
  const dispatch = useDispatch();

  const reportExists = useSelector((state) => state.analysisReports.reportExists);
  const loading = useSelector((state) => state.analysisReports.reportExistsLoading);

  let query = useQuery();
  const id = query.get("data");

  useEffect(() => {
    firstTimeLoading = true;
    dispatch(RequestCheckReportExists(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (reportExists) return;

    const intervalId = setInterval(() => {
      firstTimeLoading = false;
      dispatch(RequestCheckReportExists(id));
    }, 5000);

    return () => clearInterval(intervalId);
  }, [reportExists, id, dispatch]);

  return reportExists ? (
    <AnalysisReports />
  ) : firstTimeLoading && loading ? (
    <Backdrop show={true} absolute={firstTimeLoading && loading} message="Checking report..." />
  ) : (
    <div className="pre-report">
      <h1>Report being generated...</h1>
      <p>You will be redirected to report page once the report is ready.</p>
    </div>
  );
};

export default AnalysisReportsWrapper;
