import ExcelJS from "exceljs";
import FileSaver from "file-saver";

import XlsxIcon from "../Components/Icon-SVG/XLSXIcon";
import axiosApi from "./axios";

const manualHeaders = [
  { header: "Username", key: "user_name" },
  { header: "Submit Time", key: "submit_time" },
  { header: "Submit Type", key: "submit_type" },
  { header: "File Name", key: "file_name" },
  { header: "File Hash", key: "file_hash" },
  { header: "Count", key: "count" },
  { header: "Os", key: "os" },
  { header: "Verdict", key: "verdict" },
];

const ExportXLSX = ({
  csvData,
  fileName,
  selected,
  columns,
  manualPagination = false,
  agentHostname,
  selectedTimeFilter,
}) => {
  // const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExport;
  const fetchDataAndExportToCSV = async () => {
    let additionalUrlArgs = "";
    if (agentHostname) {
      additionalUrlArgs += `&agentHostName=${agentHostname}`;
    }
    if (selectedTimeFilter) {
      additionalUrlArgs += `&filterByLast=${selectedTimeFilter.id}`;
    }
    try {
      const response = await axiosApi.get(
        `api/dashboard/submission/export-data?tableName=${fileName}${additionalUrlArgs}`,
      );

      const workbook = new ExcelJS.Workbook(); // Create a new workbook
      const worksheet = workbook.addWorksheet("Sheet 1"); // New Worksheet

      worksheet.columns = manualHeaders;

      const dataset = response.data.map((data) =>
        manualHeaders.map(({ key }) => {
          const keys = key.split(".");
          if (keys.length > 1) {
            let value = data;
            for (const key of keys) {
              value = value[key];
            }
            return value;
          } else {
            return data[key];
          }
        }),
      );

      worksheet.addRows(dataset);

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileNameWithExtension = fileName + ".xlsx";

      const excelBuffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileNameWithExtension);
    } catch (e) {
      console.log(e);
    }
  };

  const exportToCSV = async (csvData, fileName) => {
    let newCsvData = csvData;
    if (selected) {
      newCsvData = newCsvData.map((csv) => csv.original);
    }

    const workbook = new ExcelJS.Workbook(); // Create a new workbook
    const worksheet = workbook.addWorksheet("Sheet 1"); // New Worksheet

    worksheet.columns = columns.map(({ Header, accessor }) => ({ header: Header, key: accessor }));

    const dataset = newCsvData.map((data) =>
      columns.map(({ accessor }) => {
        const keys = accessor.split(".");
        if (keys.length > 1) {
          let value = data;
          for (const key of keys) {
            value = value[key];
          }
          return value;
        } else {
          return data[accessor];
        }
      }),
    );

    worksheet.addRows(dataset);

    // const multiDataSet = [
    //   {
    //     columns: columns.map(({ Header }) => Header),
    //     data: dataset,
    //   },
    // ];

    // const excelData = [
    //   {
    //     sheetData: multiDataSet,
    //     sheetName: "Sheet 1",
    //   },
    // ];

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileNameWithExtension = fileName + ".xlsx";

    const excelBuffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileNameWithExtension);
    // const exporter = new ExcelFile(excelData);
    // exporter.saveAs(fileNameWithExtension);
  };

  return (
    <button
      style={{ background: "none", border: "none", outline: "none", cursor: "pointer" }}
      onClick={() =>
        manualPagination ? fetchDataAndExportToCSV() : exportToCSV(csvData, fileName)
      }>
      <XlsxIcon />
    </button>
  );
};

export default ExportXLSX;
