import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { loadFromLocalStorage, saveToLocalStorage } from "../../../../utils/localStorage";
import TabButton from "../../../common/TabButton/TabButton";
import CustomTable from "../../../common/Table/CustomTable";
import {
  dashboardHeader,
  dashboardMaliciousHeader,
  runningDashboardHeader,
} from "../../../../utils/constants/tableHeaders";
import { RequestRunningCount } from "../../../../store/actions/dashboardActions";

import "./styles.scss";

const DashBoardTable = () => {
  const [active, setActive] = useState(loadFromLocalStorage("dashboardListTabs") || "Latest");
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(null);
  const [selectedTimeFilterMalicious, setSelectedTimeFilterMalicious] = useState(null);

  const runningCount = useSelector((state) => state.dashboard.runningCount);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state?.fromRemoteConnection) {
      setActive("Running");
    }
  }, [location]);

  useEffect(() => {
    saveToLocalStorage("dashboardListTabs", active);
  }, [active]);

  useEffect(() => {
    dispatch(RequestRunningCount());
    const interval = setInterval(() => {
      dispatch(RequestRunningCount());
    }, 15000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (active === "Running") {
      dispatch(RequestRunningCount());
    }
  }, [active]);

  return (
    <div className="dashboard__table__container">
      <h2 className="dashboard__table__header">Submissions</h2>
      <div
        className="dashboard__table border"
        style={{ minHeight: active === "Running" ? "711px" : "761px" }}>
        <div className="button-block">
          <TabButton title="Latest" onClick={setActive} active={active} />
          <TabButton title="Malicious" onClick={setActive} active={active} />
          <TabButton title="Favorite" onClick={setActive} active={active} />
          <TabButton title="Running" tabNumber={runningCount} onClick={setActive} active={active} />
        </div>

        {active === "Latest" ? (
          <CustomTable
            exportFile={true}
            column={dashboardHeader}
            tableName={"latest"}
            setActive={setActive}
            leftAction={false}
            isSetUrl
            paginationButtons={true}
            className=""
            minBottom="5px"
            customPageSize={10}
            minHeightRow="72px"
            filterByTime={true}
            selectedTimeFilter={selectedTimeFilter}
            setSelectedTimeFilter={setSelectedTimeFilter}
            defaultFilterIndex={2}
            manualPagination={true}
          />
        ) : active === "Malicious" ? (
          <CustomTable
            exportFile={true}
            column={dashboardMaliciousHeader}
            tableName={"malicious"}
            setActive={setActive}
            leftAction={false}
            isSetUrl
            paginationButtons={true}
            className=""
            minBottom="5px"
            customPageSize={10}
            minHeightRow="72px"
            filterByTime={true}
            selectedTimeFilter={selectedTimeFilterMalicious}
            setSelectedTimeFilter={setSelectedTimeFilterMalicious}
            defaultFilterIndex={2}
            manualPagination={true}
          />
        ) : active === "Favorite" ? (
          <CustomTable
            exportFile={true}
            column={dashboardHeader}
            tableName={"favorite"}
            setActive={setActive}
            leftAction={false}
            isSetUrl
            paginationButtons={true}
            className=""
            minBottom="5px"
            customPageSize={10}
            minHeightRow="72px"
            manualPagination={true}
          />
        ) : (
          <span>
            <CustomTable
              column={runningDashboardHeader}
              tableName={"running"}
              setActive={setActive}
              leftAction={false}
              isSetUrl
              paginationButtons={true}
              className=""
              minBottom="5px"
              customPageSize={10}
              minHeightRow="67px"
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default DashBoardTable;
