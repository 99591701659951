import MalwareChart from "../../../common/MalwareChart/MalwareChart";

import MaliciousSubmissionsIcon from "../../../Icon-SVG/MaliciousSubmissionsIcon";
import SuspiciousStatesIcon from "../../../Icon-SVG/SuspiciousStatesIcon";
import CleanSubmissionsIcon from "../../../Icon-SVG/CleanSubmissionsIcon";

import "./styles.scss";

const TOTAL_SUBMISSIONS_MAX_LIMIT = 99999;
const TOTAL_INTEGTRATED_MAX_LIMIT = 99999;

const MalwareDistribution = ({
  allSubmissionsCount = 0,
  uniqueSubmissionsCount = 0,
  cleanSubmissionsState = 0,
  suspiciousSubmissionsState = 0,
  maliciousSubmissionsState = 0,
  cleanSubmissionsCount = 0,
  suspiciousSubmissionsCount = 0,
  maliciousSubmissionsCount = 0,
  allSubmissionsActive,
  setAllSubmissionsActive,
  isIntegration = false,
  aptCount = 0,
}) => {
  return (
    <div className="malware border">
      {!isIntegration && (
        <div className="malware__counter">
          <div
            onClick={() => allSubmissionsCount !== 0 && setAllSubmissionsActive("all")}
            style={{
              cursor: "pointer",
              color: allSubmissionsActive === "all" && allSubmissionsCount !== 0 && "white",
              borderColor: allSubmissionsActive === "all" && allSubmissionsCount !== 0 && "white",
            }}
            className="malware__counter-item">
            <div className="malware__counter-item-text">
              <p>
                All
                <br />
                Submissions
              </p>
            </div>
            <div className="malware__counter-item-icon">
              <span
                className="malware__counter-item-number"
                style={{
                  cursor: "pointer",
                  color: allSubmissionsActive === "all" && allSubmissionsCount !== 0 && "white",
                }}
                title={
                  allSubmissionsCount && allSubmissionsCount > TOTAL_SUBMISSIONS_MAX_LIMIT
                    ? allSubmissionsCount
                    : undefined
                }>
                <br />
                {!allSubmissionsCount
                  ? "0"
                  : allSubmissionsCount > TOTAL_SUBMISSIONS_MAX_LIMIT
                  ? `${TOTAL_SUBMISSIONS_MAX_LIMIT}+`
                  : allSubmissionsCount}
              </span>
            </div>
          </div>
          <div className="malware__counter-item">
            <div className="malware__counter-item-text">
              <p>
                Unique <br />
                Submissions
              </p>
            </div>
            <div className="malware__counter-item-icon">
              <span
                className="malware__counter-item-number"
                title={
                  uniqueSubmissionsCount && uniqueSubmissionsCount > TOTAL_INTEGTRATED_MAX_LIMIT
                    ? uniqueSubmissionsCount
                    : undefined
                }>
                <br />
                {!uniqueSubmissionsCount
                  ? "0"
                  : uniqueSubmissionsCount > TOTAL_INTEGTRATED_MAX_LIMIT
                  ? `${TOTAL_INTEGTRATED_MAX_LIMIT}+`
                  : uniqueSubmissionsCount}
              </span>
            </div>
          </div>
          <div className="malware__counter-item">
            <div className="malware__counter-item-text">
              <p>
                APT <br />
                Attacks
              </p>
            </div>
            <div className="malware__counter-item-icon">
              <span className="malware__counter-item-number">
                <br />
                {aptCount}
              </span>
            </div>
          </div>
        </div>
      )}
      <div
        className="malware__chart"
        style={isIntegration ? { marginTop: "0" } : { marginTop: "30px" }}>
        <div className="malware__chart-item">
          <MalwareChart
            chartIcon={<CleanSubmissionsIcon width={32} height={32} />}
            chartValue={cleanSubmissionsState?.toFixed(2)}
            chartName={"Clean Submissions"}
            chartColor={"#28c30f"}
            chartCount={isIntegration ? -1 : cleanSubmissionsCount}
          />
        </div>
        <div className="malware__chart-item">
          <MalwareChart
            chartIcon={<SuspiciousStatesIcon width={32} height={32} />}
            chartValue={suspiciousSubmissionsState?.toFixed(2)}
            chartName={"Suspicious States"}
            chartColor={"var(--network-blocks-snort-url-border)"}
            chartCount={isIntegration ? -1 : suspiciousSubmissionsCount}
          />
        </div>
        <div className="malware__chart-item">
          <MalwareChart
            chartIcon={<MaliciousSubmissionsIcon width={32} height={32} />}
            chartValue={maliciousSubmissionsState?.toFixed(2)}
            chartName={"Malicious Submissions"}
            chartColor={"var(--network-blocks-sbox-border)"}
            chartCount={isIntegration ? -1 : maliciousSubmissionsCount}
          />
        </div>
      </div>
    </div>
  );
};

export default MalwareDistribution;
