import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import LoadingIcon from "../../../../Icon-SVG/LoadingIcon";
import Spinner from "../../../../common/Spinner/Spinner";

import { RequestSubmissionsList } from "../../../../../store/actions/dashboardActions";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SeverityStatusStatic = (props) => {
  const dispatch = useDispatch();
  const type = props.row.original.severity || props.type;
  const fileID = props?.row?.original?.id;
  const staticResult = props?.row?.original?.staticResult || {};

  const staticResultRequestState =
    useSelector((state) => state?.analysisReports?.preStaticResults[fileID]) || {};

  return (
    <div
      className={`severity-status severity-status__${
        staticResultRequestState && staticResultRequestState.loading
          ? type === "n/a"
            ? "none"
            : type === "loading"
            ? "loading"
            : ""
          : type === "n/a"
          ? "none"
          : type === "not suspicious"
          ? "success"
          : type === "malicious"
          ? "secondary"
          : type === "suspicious"
          ? "suspicious"
          : "loading"
      }`}>
      {type === "loading" ? (
        <LoadingIcon color={"var(--text-color-card)"} />
      ) : staticResultRequestState && staticResultRequestState.loading ? (
        <Spinner width={16} height={16} />
      ) : type === "malicious" && staticResult.classification ? (
        `#${staticResult.classification[0]}`
      ) : (
        type
      )}
    </div>
  );
};

export default SeverityStatusStatic;
