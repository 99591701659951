import React from "react";
import MalwareDistribution from "../../../Components/PagesComponents/Dashboard/MalwareDistribution/MalwareDistribution";
import { dashBoardLatestSubmissions } from "../../../fixture";
import { usbTransferHeader } from "../../../utils/constants/tableHeaders";
import CustomTable from "../../../Components/common/Table/CustomTable";
import Card from "../../../Components/common/Card/Card";
import ChartLineIcon from "../../../Components/Icon-SVG/ChartLineIcon";
import "./styles.scss";

const Email = () => {
  return (
    <div>
      <div className="email">
        <div className="email__header">
          <h1>USB Data Transfer</h1>
          <p>Detection of malware by USB Flash tracking.</p>
        </div>
        <div className="email__body">
          <div className="email__body__item">
            <MalwareDistribution totalSubmissions={10} email={true} />
          </div>
          <table className="border">
            <thead>
              <tr>Most Used File Names</tr>
            </thead>
            <tbody>
              <tr>Password</tr>
              <tr>Policy</tr>
              <tr>Meeting</tr>
              <tr>Shared</tr>
              <tr>Information</tr>
            </tbody>
          </table>
          <table className="border">
            <thead>
              <tr>F://</tr>
            </thead>
            <tbody>
              <tr>Please Insert USB Flash</tr>
            </tbody>
          </table>
          <div className="email__body__card">
            <Card
              title="Daily USB Data Transfer"
              value={3}
              icon={<ChartLineIcon width={18} height={17} color={"white"} />}
            />
            <Card
              title="Total USB Data Transfer"
              value={8}
              icon={<ChartLineIcon width={18} height={17} color={"white"} />}
            />
          </div>
        </div>
        <div
          className="email__table border"
          style={{ width: "100%", minHeight: "40rem", position: "relative" }}>
          <CustomTable
            exportFile={true}
            column={usbTransferHeader}
            tableName={"usbtransfer"}
            leftAction={false}
            dataEmail={dashBoardLatestSubmissions}
            isSetUrl
            paginationButtons={true}
            className=""
            minBottom="96px"
            customPageSize={8}
            minHeightRow="72px"
          />
        </div>
      </div>
    </div>
  );
};
export default Email;
