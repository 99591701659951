import React from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import CustomTable from "../../../common/Table/CustomTable";
import PreStaticResultsTable from "../../../common/PreStaticResultsTable/PreStaticResultsTable";
import {
  listPopupHeader,
  emailPopupHeader,
  filesharePopupHeader,
  proxyPopupHeader,
  postfixemailPopupHeader,
  compressedHeader,
  runningPopupHeader,
  endpointPopupHeader,
  compressedStaticResultsHeader,
} from "../../../../utils/constants/tableHeaders";

const ListPopup = ({
  show,
  groupID,
  tableType,
  tableType2 = "",
  agentHostname = "",
  setShow = () => {},
}) => {
  const history = useHistory();

  const closePopupHandler = () => {
    let route = "";
    switch (tableType2) {
      case "historical":
        route = "/historical-analysis?";
        break;
      case "imapemail":
        route = "/imapemail?";
        break;
      case "fileshare":
        route = "/sharepoint?";
        break;
      case "winshare":
        route = "/fileshare?";
        break;
      case "icapweb":
        route = "/proxy?";
        break;
      case "endpoint":
        route = "/submissions?";
        break;
      case "postfixemail":
        route = "/postfixemail?";
        break;
      case "dashboard":
        route = "/dashboard?";
        break;
      case "agent":
        route = "/agentstatus?host=" + agentHostname;
        break;
      case "outlook":
        route = "/outlook?";
        break;
      case "network-detections":
        route = "/network-detections?";
        break;
      default:
        break;
    }
    if (tableType2 === "analysisReportsPopup") {
      setShow(false);
    } else {
      history.push(route);
    }
  };

  const renderCustomTable = () => {
    let column = listPopupHeader;
    let tableName = "listPopup";

    switch (tableType) {
      case "outlook":
        column = listPopupHeader;
        tableName = "listOutlookPopup";
        break;
      case "malicious":
        column = listPopupHeader;
        tableName = "listMaliciousPopup";
        break;
      case "imapemail":
        column = emailPopupHeader;
        tableName = "listImapEmailPopup";
        break;
      case "fileshare":
      case "winshare":
        column = filesharePopupHeader;
        tableName = tableType === "fileshare" ? "listFilesharePopup" : "listWinsharePopup";
        break;
      case "icapweb":
        column = proxyPopupHeader;
        tableName = "listProxyPopup";
        break;
      case "endpoint":
        column = endpointPopupHeader;
        tableName = "listEndpointPopup";
        break;
      case "postfixemail":
        column = postfixemailPopupHeader;
        tableName = "listPostfixEmailPopup";
        break;
      case "compressed":
        column = compressedHeader;
        tableName = "compressed";
        break;
      case "compressed-network-detections":
        column = compressedHeader;
        tableName = "compressed-network-detections";
        break;
      case "running":
        column = runningPopupHeader;
        tableName = "runningPopup";
        break;
      case "network-detections":
        column = listPopupHeader;
        tableName = "listNetworkDetectionsPopup";
        break;
      case "compressed-static-results":
        column = compressedStaticResultsHeader;
        tableName = "compressedStaticResults";
        break;
      case "static-results":
        tableName = "staticResults";
        break;
      case "analysisReportsPopup":
        tableName = "analysisReportsPopup";
        break;
      default:
        break;
    }

    if (tableName === "staticResults" || tableName === "compressedStaticResults") {
      return <PreStaticResultsTable tableName={tableName} />;
    } else {
      return (
        <CustomTable
          column={column}
          tableName={tableName}
          leftAction={false}
          tableFolder={tableName}
          isSetUrl
          paginationButtons={false}
          className=""
          listGroupData={groupID}
          refresh={false}
          customPageSize={200}
          minHeight={50}
        />
      );
    }
  };

  return (
    <div className={`${show && "listGroup-backdrop"}`} onClick={closePopupHandler}>
      <div onClick={(e) => e.stopPropagation()}>
        {show && (
          <div
            className={`listGroup border ${
              tableType === "compressed" ||
              tableType === "compressed-static-results" ||
              tableType === "compressed-network-detections"
                ? "listGroup--compressed"
                : tableType === "static-results"
                ? "listGroup--staticResults"
                : ""
            }`}>
            <div className="listGroup-buttons">
              {(tableType === "compressed" ||
                tableType === "compressed-network-detections" ||
                history.location?.state?.from === "compressedStaticResults") && (
                <div onClick={history.goBack} className="listGroup-buttons__go-back">
                  &larr;
                </div>
              )}
              <div className="listGroup-close-button__outer">
                <div onClick={closePopupHandler} className="listGroup-close-button__inner">
                  <label className="listGroup-close-button__close">Close</label>
                </div>
              </div>
            </div>
            {tableType === "compressed" ||
            tableType === "compressed-network-detections" ||
            tableType === "running" ||
            tableType === "compressed-static-results" ||
            tableType === "static-results" ? null : (
              <table style={{ width: "90%", borderBottom: "1px solid white", color: "white" }}>
                <tbody>
                  <tr style={{ width: "90%" }}>
                    <td style={{ paddingLeft: "10px" }}>Type</td>
                    <td style={{ paddingLeft: "20px" }}>Network Traffic</td>
                    <td style={{ paddingRight: "40px" }}>Os</td>
                    <td style={{ paddingRight: "15px" }}>Verdict</td>
                  </tr>
                </tbody>
              </table>
            )}
            <div
              className={`listGroupTable ${
                tableType === "compressed" ||
                tableType === "compressed-static-results" ||
                tableType === "compressed-network-detections"
                  ? "listGroupTable--compressed"
                  : tableType === "static-results"
                  ? "listGroupTable--staticResults"
                  : ""
              }`}>
              {renderCustomTable()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListPopup;
