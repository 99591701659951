import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CopyClipboard } from "../../../../../store/actions/dashboardActions";
import { RequestDeleteConfig } from "../../../../../store/actions/integrationsActions";
import "./styles.scss";

const syncLocalStorageForNetworkDetections = (id, isExpanded) => {
  const storedData = localStorage.getItem("networkDetectionsToggle");
  let parsedData = { ids: [] };
  if (storedData) {
    parsedData = JSON.parse(storedData);
  }
  if (!isExpanded) {
    if (!parsedData.ids.includes(id)) {
      parsedData.ids.push(id);
    }
  } else {
    const newIds = parsedData.ids.filter((el) => el !== id);
    parsedData = { ids: newIds };
  }
  localStorage.setItem("networkDetectionsToggle", JSON.stringify(parsedData));
};

const Row = ({
  row,
  minHeight,
  setCurrentFile,
  renderRowSubComponent = null,
  visibleColumns,
  isSetUrl = false,
  tableName,
  reportPage,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const status = row?.original?.severity;
  const fileUrl = row?.original?.file?.url;
  const _id = row?.original?._id?.$oid;
  const isStatic = row?.original?.isStatic;
  const staticResultLink = row?.original?.staticResultLink;

  let networkBlockDetection = "";
  if (tableName === "networkDetections") {
    networkBlockDetection = row?.original?.log?.detection;
  }

  useEffect(() => {
    if (networkBlockDetection) {
      const storedData = localStorage.getItem("networkDetectionsToggle");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        if (parsedData.ids && parsedData.ids.includes(_id)) {
          row.toggleRowExpanded();
        }
      }
    }
  }, [networkBlockDetection, _id, row]);

  return (
    <>
      <tr
        className={`custom-table-row custom-table-row--dynamic ${
          tableName === "overview"
            ? status >= 3
              ? "custom-table-row--malicious"
              : status > 0
              ? "custom-table-row--suspicious"
              : "custom-table-row--clean"
            : tableName === "networkDetections"
            ? `custom-table-row--${networkBlockDetection}`
            : ""
        }`}
        style={{ height: minHeight || "auto" }}
        {...row.getRowProps()}>
        {row.cells.map((cell, i) => (
          <td
            {...cell.getCellProps()}
            className={
              networkBlockDetection === "DomainBlock" && i === 0
                ? cell.column.rowClassName
                  ? cell.column.rowClassName + " hidden"
                  : "hidden"
                : cell.column.rowClassName
            }
            onClick={() => {
              setCurrentFile && setCurrentFile(row.original);

              if (cell.column.event !== "copy") {
                if (fileUrl !== "" && fileUrl && isSetUrl && !fileUrl.endsWith("loading")) {
                  if (!isStatic) {
                    if (tableName === "compressedStaticResults") {
                      history.push(fileUrl, { from: "compressedStaticResults" });
                    } else {
                      history.push(fileUrl, { clickedFileID: row?.original?.id });
                    }
                  } else {
                    if (staticResultLink) {
                      history.push(staticResultLink);
                    }
                  }
                } else if (renderRowSubComponent) {
                  if (networkBlockDetection !== "DomainBlock") {
                    if (networkBlockDetection) {
                      syncLocalStorageForNetworkDetections(_id, row.isExpanded);
                    }
                    row.toggleRowExpanded();
                  }
                }
              }
            }}
            style={reportPage ? { backgroundColor: "var(--header-background)" } : {}}>
            {cell.render("Cell")}
          </td>
        ))}
      </tr>
      {row.isExpanded ? (
        <tr
          className="custom-table-row-expansion"
          style={reportPage ? { backgroundColor: "var(--header-background)" } : {}}>
          <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row, history })}</td>
        </tr>
      ) : null}
    </>
  );
};

export default Row;
