import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import AddIcon from "../../Icon-SVG/AddIcon";

import IntegrationConfigListHeader from "./Components/IntegrationConfigListHeader";
import IntegrationConfigListBody from "./Components/IntegrationConfigListBody";

import "./styles.scss";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const IntegrationConfigList = ({ show, setShow, activeInt, setActiveInt, data }) => {
  const history = useHistory();
  let query = useQuery();
  const [hoverAdd, setHoverAdd] = useState(false);

  const closePopupHandler = () => {
    setShow(false);
    setActiveInt("");
    if (query.get("bck") !== null) {
      setActiveInt("");
      history.push("/integration-market");
    }
  };

  const closeListHandler = () => {
    setShow(false);
    setActiveInt("");
  };
  const rowOnClickHandler = (int, i) => {
    history.push(`integration-market?conf=${int}&t=${i}`);

    closeListHandler();
  };

  return (
    <div className={`${show && "integration-config-backdrop"}`} onClick={closePopupHandler}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {show ? (
          <div className={"integration-config border"}>
            <div className="integration-config-buttons">
              <div className="integration-config-close-button__outer">
                <div onClick={closePopupHandler} className="integration-config-close-button__inner">
                  <label className="integration-config-close-button__close">Close</label>
                </div>
              </div>
            </div>
            <div className={"integration-configTable-wrapper"}>
              <IntegrationConfigListHeader
                columns={data.listPopup.titles ? data.listPopup.titles : []}
                trash={data.listPopup.trashButton}
              />
              <IntegrationConfigListBody
                onClickHandler={rowOnClickHandler}
                intname={data.intname}
                columns={data.listPopup.titles ? data.listPopup.titles : []}
                trash={data.listPopup.trashButton}
                mountPoint={data.listPopup.mountPoint ? data.listPopup.mountPoint : null}
              />
            </div>
            <button
              title="Add"
              onMouseEnter={() => setHoverAdd(true)}
              onMouseLeave={() => setHoverAdd(false)}
              onClick={() => (
                history.push(`integration-market?conf=${activeInt}&t=add`), closeListHandler()
              )}
              className="integration-config__addicon">
              <AddIcon
                width={23}
                height={23}
                color={hoverAdd ? "var(--header-search-text-color)" : "var(--color-blue)"}
                backcolor="transparent"
              />
              <span
                style={{
                  color: hoverAdd ? "var(--header-search-text-color)" : "var(--color-blue)",
                }}>
                Add Config
              </span>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default IntegrationConfigList;
