import { call, put, takeEvery } from "redux-saga/effects";
import axiosApi from "../../utils/axios";
import {
  RequestDeployInt,
  SuccessDeployInt,
  FailureDeployInt,
  RequestGetConfig,
  SuccessGetConfig,
  FailureGetConfig,
  RequestDeleteInt,
  SuccessDeleteInt,
  FailureDeleteInt,
  RequestSetConfig,
  SuccessSetConfig,
  FailureSetConfig,
  RequestCheckConfig,
  SuccessCheckConfig,
  FailureCheckConfig,
  RequestDeleteConfig,
  SuccessDeleteConfig,
  FailureDeleteConfig,
  RequestUpdateConfig,
  SuccessUpdateConfig,
  FailureUpdateConfig,
  RequestAgentStatus,
  SuccessAgentStatus,
  FailureAgentStatus,
} from "../actions/integrationsActions";
import { customFormData } from "../../utils/customFormData";
import { addNotification } from "../actions/notifierActions";

export function* deployInt({ payload: intname }) {
  try {
    const response = yield axiosApi.get(`/api/kubernetes/deployintegration/${intname}`, {
      timeout: 400000,
    });
    yield checkConfig();
    yield put(SuccessDeployInt(response?.data));
    yield put(
      addNotification({ message: response?.data.message, options: { variant: "success" } }),
    );
  } catch (error) {
    if (error.response?.status) {
      yield checkConfig();
    } else {
      yield put(FailureDeployInt(error.response?.status));
      yield put(
        addNotification({ message: error.response?.data.message, options: { variant: "error" } }),
      );
    }
  }
}

export function* deleteInt({ payload: intname }) {
  try {
    const response = yield axiosApi.get(`/api/kubernetes/deleteintegration/${intname}`);
    yield put(SuccessDeleteInt(response?.data));
    yield put(
      addNotification({ message: response?.data.message, options: { variant: "success" } }),
    );
  } catch (error) {
    yield put(FailureDeleteInt(error));
    yield put(addNotification({ message: "Error", options: { variant: "error" } }));
  }
}

export function* setConfig({ payload: data }) {
  const formData = customFormData(data.appname);
  formData.append("name", data.appname);
  formData.append("config", data.config);
  try {
    const response = yield axiosApi.post("/api/kubernetes/addconfig", formData);
    yield put(SuccessSetConfig("Success"));
    if (data.type != "update") {
      yield put(
        addNotification({ message: response?.data.message, options: { variant: "success" } }),
      );
    }
  } catch (error) {
    yield put(FailureSetConfig(error));
    yield put(
      addNotification({ message: error.response?.data.message, options: { variant: "error" } }),
    );
  }
}

export function* getConfig({ payload: intname }) {
  try {
    const response = yield axiosApi.get(`/api/kubernetes/configview/${intname}`);
    yield put(SuccessGetConfig(response?.data));
  } catch (error) {
    yield put(FailureGetConfig(error));
  }
}

export function* checkConfig() {
  try {
    const response = yield axiosApi.get("/api/kubernetes/checkconfig");
    yield put(SuccessCheckConfig(response?.data));
  } catch (error) {
    yield put(FailureCheckConfig(error));
  }
}

export function* deleteConfig({ payload: data }) {
  const jsondata = {
    intname: data.inttype,
    mountpoint: data.mountpoint,
  };

  try {
    const response = yield axiosApi.delete("/api/kubernetes/deleteconfig", { data: jsondata });
    yield put(SuccessDeleteConfig("Success"));
    if (data.type !== "update") {
      yield call(getConfig, { payload: data.inttype });

      yield put(
        addNotification({ message: response?.data.message, options: { variant: "success" } }),
      );
    }
  } catch (error) {
    yield put(FailureDeleteConfig(error));
    yield put(
      addNotification({ message: error.response?.data.message, options: { variant: "error" } }),
    );
  }
}

export function* updateConfig({ payload: data }) {
  try {
    yield call(deleteConfig, {
      payload: { inttype: data.appname, mountpoint: data.mountpoint, type: "update" },
    });
    yield call(setConfig, {
      payload: { appname: data.appname, config: data.config, type: "update" },
    });
    yield put(SuccessUpdateConfig("Success"));
    yield put(addNotification({ message: "Config Updated", options: { variant: "success" } }));
  } catch (error) {
    yield put(FailureUpdateConfig(error));
    yield put(addNotification({ message: "Config Updated Failed", options: { variant: "error" } }));
  }
}

export function* agentStatus() {
  try {
    const response = yield axiosApi.get("/api/agent/list");
    yield put(SuccessAgentStatus(response?.data));
  } catch (error) {
    yield put(FailureAgentStatus(error));
  }
}

const integrationsSagas = [
  takeEvery(RequestDeployInt, deployInt),
  takeEvery(RequestGetConfig, getConfig),
  takeEvery(RequestDeleteInt, deleteInt),
  takeEvery(RequestSetConfig, setConfig),
  takeEvery(RequestUpdateConfig, updateConfig),
  takeEvery(RequestCheckConfig, checkConfig),
  takeEvery(RequestDeleteConfig, deleteConfig),
  takeEvery(RequestAgentStatus, agentStatus),
];

export default integrationsSagas;
