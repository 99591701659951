import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import useResizeWindow from "../../../utils/hook/useResizeWindows";
import { RequestGetUILogo } from "../../../store/actions/dashboardActions";

import LogoIcon from "../../Icon-SVG/LogoIcon";
import MenIcon from "../../Icon-SVG/MenIcon";
import DoughnutChartIcon from "../../Icon-SVG/DoughnutChartIcon";
import StatisticsIcon from "../../Icon-SVG/StatisticsIcon";
import DemoPopup from "../../common/DemoPopup/DemoPopup";
import InfoIcon from "../../Icon-SVG/InfoIcon";
import MarketIcon from "../../Icon-SVG/MarketIcon";
import SidebarItem from "./SidebarItem";
import IntegrationsIcon from "../../Icon-SVG/IntegrationsIcon";
import EmailIntegrationIcon from "../../Icon-SVG/EmailIntegrationIcon";
import FileIcon from "../../Icon-SVG/FileIcon";
import WebIcon from "../../Icon-SVG/WebIcon";
import EndpointIcon from "../../Icon-SVG/EndpointIcon";
import MessageIcon from "../../Icon-SVG/MessageIcon";
import OutlookIcon from "../../Icon-SVG/OutlookIcon";
import SharePointIcon from "../../Icon-SVG/SharePointIcon";
import FileSharesIcon from "../../Icon-SVG/FileSharesIcon";
import IcapIcon from "../../Icon-SVG/IcapIcon";
import EndpointMainIcon from "../../Icon-SVG/EndpointMainIcon";
import UsbIcon from "../../Icon-SVG/UsbIcon";
import AgentIcon from "../../Icon-SVG/AgentIcon";
import SettingIcon from "../../Icon-SVG/SettingIcon";
import NetworkDefenderIcon from "../../Icon-SVG/NetworkDefenderIcon";
import NetworkDetectionsIcon from "../../Icon-SVG/NetworkDetectionsIcon";
import UserIcon from "../../Icon-SVG/UserIcon";
import DataRetentionIcon from "../../Icon-SVG/DataRetentionIcon";
import DumpAndRestoreIcon from "../../Icon-SVG/DumpAndRestoreIcon";
import SyslogIcon from "../../Icon-SVG/SyslogIcon";
import SocialIcon from "../../Icon-SVG/SocialIcon";
import IocIcon from "../../Icon-SVG/IocIcon";
import UrlIcon from "../../Icon-SVG/UrlIcon";
import DomainIcon from "../../Icon-SVG/DomainIcon";
import { loadFromLocalStorage } from "../../../utils/localStorage";
import DataflowLogo from "../../assets/dataflowLogo.png";
import DataflowLogoShort from "../../assets/dataflowLogoShort.png";

import "./styles.scss";
import DataFlowText from "../../Icon-SVG/DataFlowText";
import DataFlowIcon from "../../Icon-SVG/DataFlowIcon";
import PublicApiIcon from "../../Icon-SVG/PublicApiIcon";

const dashboardItem = {
  to: "/dashboard",
  icon: (pathname) => (
    <DoughnutChartIcon
      width={21}
      height={21}
      color={pathname === "/dashboard" && "var(--sidebar-active-icon)"}
    />
  ),
  text: "Dashboard",
};

const historicalResultsItem = {
  to: "/historical-analysis",
  icon: (pathname) => (
    <MenIcon
      width={21}
      height={21}
      color={pathname === "/historical-analysis" && "var(--sidebar-active-icon)"}
    />
  ),
  text: "Historical Results",
};

const integrationsItem = {
  to: "#",
  icon: (pathname) => <IntegrationsIcon width={21} height={21} />,
  text: "Integrations",
  subItems: [
    {
      to: "#",
      icon: (pathname) => (
        <EmailIntegrationIcon width={18} height={18} color="var(--sidebar-item-secondary)" />
      ),
      text: "Email",
      subItems: [
        {
          to: "/imapemail",
          icon: (pathname, to) => (
            <MessageIcon
              width={18}
              height={18}
              color={pathname === to ? "var(--sidebar-active-icon)" : "var(--text-color-card)"}
            />
          ),
          text: "Imap Email",
        },
        {
          to: "/postfixemail",
          icon: (pathname, to) => (
            <MessageIcon
              width={18}
              height={18}
              color={pathname === to ? "var(--sidebar-active-icon)" : "var(--text-color-card)"}
            />
          ),
          text: "Postfix Email",
        },
        {
          to: "/outlook",
          icon: (pathname, to) => (
            <OutlookIcon
              width={18}
              height={18}
              color={pathname === to ? "var(--sidebar-active-icon)" : "var(--text-color-card)"}
            />
          ),
          text: "Outlook Add-In",
        },
      ],
    },
    {
      to: "#",
      icon: (pathname) => <FileIcon width={18} height={18} color="var(--sidebar-item-secondary)" />,
      text: "File",
      subItems: [
        {
          to: "/sharepoint",
          icon: (pathname, to) => (
            <SharePointIcon
              width={18}
              height={18}
              color={pathname === to ? "var(--sidebar-active-icon)" : "var(--text-color-card)"}
            />
          ),
          text: "SharePoint",
        },
        {
          to: "/fileshare",
          icon: (pathname, to) => (
            <FileSharesIcon
              width={18}
              height={18}
              color={pathname === to ? "var(--sidebar-active-icon)" : "var(--text-color-card)"}
            />
          ),
          text: "File Share",
        },
      ],
    },
    {
      to: "#",
      icon: (pathname) => <WebIcon width={18} height={18} color="var(--sidebar-item-secondary)" />,
      text: "Web",
      subItems: [
        {
          to: "/proxy",
          icon: (pathname, to) => (
            <IcapIcon
              width={18}
              height={18}
              color={pathname === to ? "var(--sidebar-active-icon)" : "var(--text-color-card)"}
            />
          ),
          text: "ICAP Proxy",
        },
      ],
    },
    {
      to: "#",
      icon: (pathname) => (
        <EndpointIcon width={18} height={18} color="var(--sidebar-item-secondary)" />
      ),
      text: "Endpoint",
      subItems: [
        {
          to: "/submissions",
          icon: (pathname, to) => (
            <EndpointMainIcon
              width={18}
              height={18}
              color={pathname === to ? "var(--sidebar-active-icon)" : "var(--text-color-card)"}
            />
          ),
          text: "Submissions",
        },
        {
          to: "/usbtransfer",
          icon: (pathname, to) => (
            <UsbIcon
              width={18}
              height={18}
              color={pathname === to ? "var(--sidebar-active-icon)" : "var(--text-color-card)"}
            />
          ),
          text: "USB Transfer",
        },
      ],
    },
  ],
};

const agentsItem = {
  to: "#",
  icon: (pathname) => <AgentIcon width={21} height={21} />,
  text: "Agents",
  subItems: [
    {
      to: "/agentstatus",
      icon: (pathname, to) => (
        <InfoIcon
          width={18}
          height={18}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Agent Status",
    },
    {
      to: "/agentconfigs",
      icon: (pathname, to) => (
        <SettingIcon
          width={18}
          height={18}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Agent Configs",
    },
  ],
};

const networkDefenderItem = {
  to: "#",
  icon: (pathname) => <NetworkDefenderIcon width={21} height={21} />,
  text: "Network Defender",
  subItems: [
    {
      to: "/network-detections",
      icon: (pathname, to) => (
        <NetworkDetectionsIcon
          width={18}
          height={18}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Network Detections",
    },
  ],
};

const iocItem = {
  to: "#",
  icon: (pathname, to) => <IocIcon />,
  text: "IOCs",
  subItems: [
    {
      to: "/iocs/urls",
      icon: (pathname, to) => (
        <UrlIcon
          width={20}
          height={20}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Urls",
    },
    {
      to: "/iocs/hosts-and-domains",
      icon: (pathname, to) => (
        <DomainIcon
          width={20}
          height={20}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Hosts and Domains",
    },
  ],
};

const statisticsItem = {
  to: "/statistics",
  icon: (pathname, to) => (
    <StatisticsIcon color={pathname === "/statistics" && "var(--sidebar-active-icon)"} />
  ),
  text: "Statistics",
};

const settingsItem = {
  to: "#",
  icon: (pathname) => <SettingIcon width={21} height={21} />,
  text: "Settings",
  subItems: [
    {
      to: "/settings/user-accounts",
      icon: (pathname, to) => (
        <UserIcon
          width={18}
          height={18}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "User Accounts",
    },
    {
      to: "/settings/data-retention",
      icon: (pathname, to) => (
        <DataRetentionIcon
          width={22}
          height={22}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Data Retention",
    },
    {
      to: "/settings/backup-and-restore",
      icon: (pathname, to) => (
        <DumpAndRestoreIcon
          width={22}
          height={22}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Backup & Restore",
    },
    {
      to: "/settings/mail-notifications",
      icon: (pathname, to) => (
        <EmailIntegrationIcon
          width={21}
          height={21}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Mail Notifications",
    },
    {
      to: "/settings/syslog",
      icon: (pathname, to) => (
        <SyslogIcon
          width={21}
          height={21}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Syslog",
    },
    {
      to: "/settings/publicapi",
      icon: (pathname, to) => (
        <PublicApiIcon
          width={21}
          height={21}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Public API",
    },
    {
      to: "/settings/socialnotification",
      icon: (pathname, to) => (
        <SocialIcon
          width={21}
          height={21}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Social Notification",
    },
  ],
};

const publicItem = {
  to: "#",
  icon: (pathname) => <SettingIcon width={21} height={21} />,
  text: "Settings",
  subItems: [
    {
      to: "/settings/publicapi",
      icon: (pathname, to) => (
        <PublicApiIcon
          width={21}
          height={21}
          color={pathname === to ? "var(--sidebar-active-icon)" : "var(--sidebar-item-secondary)"}
        />
      ),
      text: "Public API",
    },
  ],
};

const infoItem = {
  to: "/system-info",
  icon: (pathname) => (
    <InfoIcon
      width={21}
      height={21}
      color={pathname === "/system-info" && "var(--sidebar-active-icon)"}
    />
  ),
  text: "Info",
};

const integrationMarketItem = {
  to: "/integration-market",
  icon: (pathname) => (
    <MarketIcon
      width={21}
      height={21}
      color={pathname === "/integration-market" && "var(--sidebar-active-icon)"}
    />
  ),
  text: "Integration Market",
};

const Sidebar = ({ collapsed, fullyCollapsed }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const width = useResizeWindow();
  const theme = loadFromLocalStorage("theme");

  const role = useSelector((state) => state?.users?.user?.role) || "USER";
  const [showDelete, setShowDelete] = useState(false);
  const [clickedExpandableItem, setClickedExpandableItem] = useState("");
  const [clickedExpandableSubItem, setClickedExpandableSubItem] = useState("");

  const expandableItemClickHandler = (e, to, text) => {
    if (to === "#") {
      e.preventDefault();
    }
    setClickedExpandableItem((prevState) => {
      if (prevState === text) {
        return "";
      } else {
        return text;
      }
    });
  };

  const expandableSubItemClickHandler = (e, to, text) => {
    if (to === "#") {
      e.preventDefault();
    }
    setClickedExpandableSubItem((prevState) => {
      if (prevState === text) {
        return "";
      } else {
        return text;
      }
    });
  };

  if (fullyCollapsed) return;

  return (
    <div
      className="sidebar"
      style={{
        width: fullyCollapsed ? "0" : collapsed ? "80px" : width <= 1350 ? "180px" : "220px",
      }}>
      <Link to="/dashboard" className="logo">
        <div className="logo__icon">
          {collapsed ? (
            theme === "dataflowx" ? (
              <DataFlowIcon width={30} />
            ) : (
              <LogoIcon width={23} />
            )
          ) : theme === "dataflowx" ? (
            <section>
              <DataFlowText width={150} /> <DataFlowIcon width={23} />
            </section>
          ) : (
            <div className="sidebar__logo">
              <div className="sidebar__logo__icon">
                <LogoIcon width={23} />
              </div>
              <div className="sidebar__logo__text">
                <strong className="sidebar__logo__text-cyber">Cyber</strong>
                <strong className="sidebar__logo__text-fortress">Fortress</strong>
              </div>
            </div>
          )}
        </div>
      </Link>
      <div className="sidebar__scrollableMenu">
        <div className={`menu ${collapsed ? "menu__border-bottom2" : "menu__border-bottom"}`}>
          <SidebarItem collapsed={collapsed} item={dashboardItem} />
          <SidebarItem collapsed={collapsed} item={historicalResultsItem} />
          <SidebarItem
            collapsed={collapsed}
            item={integrationsItem}
            clickedExpandableItem={clickedExpandableItem}
            expandableItemClickHandler={expandableItemClickHandler}
            clickedExpandableSubItem={clickedExpandableSubItem}
            expandableSubItemClickHandler={expandableSubItemClickHandler}
          />
          <SidebarItem
            collapsed={collapsed}
            item={agentsItem}
            clickedExpandableItem={clickedExpandableItem}
            expandableItemClickHandler={expandableItemClickHandler}
          />
          <SidebarItem
            collapsed={collapsed}
            item={networkDefenderItem}
            clickedExpandableItem={clickedExpandableItem}
            expandableItemClickHandler={expandableItemClickHandler}
          />
          <SidebarItem
            collapsed={collapsed}
            item={iocItem}
            clickedExpandableItem={clickedExpandableItem}
            expandableItemClickHandler={expandableItemClickHandler}
          />
          <SidebarItem collapsed={collapsed} item={statisticsItem} />
        </div>
        <div className={`menu ${collapsed ? "menu__border-bottom2" : "menu__border-bottom"}`}>
          {(role === "ADMIN" || role === "SUPERADMIN") && (
            <SidebarItem
              collapsed={collapsed}
              item={settingsItem}
              clickedExpandableItem={clickedExpandableItem}
              expandableItemClickHandler={expandableItemClickHandler}
            />
          )}
          {(role === "USER") && (
            <SidebarItem
              collapsed={collapsed}
              item={publicItem}
              clickedExpandableItem={clickedExpandableItem}
              expandableItemClickHandler={expandableItemClickHandler}
            />
          )}
          {(role === "ADMIN" || role === "SUPERADMIN") && (
            <SidebarItem collapsed={collapsed} item={infoItem} />
          )}
        </div>
        <div style={{ paddingBottom: "20px", marginBottom: "20px" }}>
          {(role === "ADMIN" || role === "SUPERADMIN") && (
            <SidebarItem collapsed={collapsed} item={integrationMarketItem} />
          )}
        </div>
      </div>
      <DemoPopup
        onClickOk={() => setShowDelete(false)}
        show={showDelete}
        setShow={setShowDelete}
        text="This feature is not available in the demo version."
        article="DEMO"
      />
    </div>
  );
};

export default Sidebar;
