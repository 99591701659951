import { useSelector } from "react-redux";

import TrashButton from "./TrashButton";

const IntegrationConfigListBody = ({ onClickHandler, intname, columns, trash, mountPoint }) => {
  const getConfig = useSelector((state) => state.integrations.getConfig);

  return (
    <div className="integration-configTable-body-wrapper">
      {getConfig && getConfig.config && Array.isArray(getConfig.config) ? (
        <table className="integration-configTable-body">
          <tbody>
            {getConfig.config.map((conf, i) => (
              <tr key={i} style={{ height: "55px" }} onClick={() => onClickHandler(intname, i)}>
                {columns.map((column) => (
                  <td
                    key={column.title ? column.title : ""}
                    style={{ width: column.width ? column.width : "30%" }}>
                    {typeof column.data === "string" || column.data instanceof String
                      ? conf[column.data]
                      : typeof column.data === "function"
                      ? column.data(conf)
                      : ""}
                  </td>
                ))}
                {trash && trash.enabled ? (
                  <td
                    key={"delete"}
                    style={{
                      width: trash && trash.enabled ? (trash.width ? trash.width : "10%") : "",
                    }}>
                    <TrashButton intname={intname} mountPointData={conf[mountPoint]} />
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="no-data">
          <tbody>
            <tr>
              <td colSpan={trash && trash.enabled ? columns.length + 1 : columns.length}>
                No Data
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};
export default IntegrationConfigListBody;
