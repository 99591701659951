export const prepareStaticInfoData = (subCatField, subCategories) => {
  if (subCatField === "clamav") {
    const items = subCategories[subCatField];
    return {
      title: `AV Detection${items?.length > 1 ? "s" : ""}`,
      items,
    };
  }
  if (subCatField === "metadefenfer") {
    return {
      title: "Metadefender Classification",
      items: subCategories[subCatField]?.threat_name
        ? [subCategories[subCatField].threat_name]
        : [],
    };
  }
  if (subCatField === "yara") {
    return {
      title: "All YARA Rule Matches",
      items: subCategories[subCatField]
        ?.map((yara) => {
          const subItems = [];
          const obj = {};

          if (yara?.meta?.description)
            obj["description"] = yara.meta.description.replace(/_Weak$/, "");
          if (yara?.meta?.Description)
            obj["description"] = yara.meta.Description.replace(/_Weak$/, "");
          if (yara?.strings?.length) obj["matches"] = yara.strings;

          if (Object.keys(obj).includes("description") || Object.keys(obj).includes("matches"))
            subItems.push(obj);

          return {
            subTitle: yara?.name?.replace(/_Weak$/, ""),
            subItems,
            collapsed: true,
          };
        })
        .filter(
          (yara, idx, arr) => idx === arr.findIndex((item) => item.subTitle === yara.subTitle),
        ),
    };
  }
  if (subCatField === "virustotal") {
    const virusTotalData = subCategories[subCatField]["popular_threat_classification"];
    const items = [];

    if (virusTotalData) {
      const subTitle = virusTotalData["suggested_threat_label"];
      const obj = {};

      obj["threatCategories"] = virusTotalData["popular_threat_category"]?.map((ptc) => ptc.value);
      obj["familyLabels"] = virusTotalData["popular_threat_name"]?.map((ptn) => ptn.value);
      items.push({ subTitle, subItems: [obj], collapsed: true });
    }

    return {
      title: "Virustotal Classification",
      items: items,
    };
  }
  if (subCatField === "safebrowsing") {
    const matches = subCategories[subCatField]?.matches;
    const sliceLength = matches?.length < 4 ? matches.length : 4;
    return {
      title: `Prevalance Match${matches?.length > 1 ? "es" : ""}`,
      items: matches?.length
        ? [
            {
              subTitle: `Match at ${matches.length} page${
                matches.length > 1 ? "s" : ""
              }. Expand to see ${
                matches.length > 1 ? `first ${sliceLength} matches` : "first match"
              }.`,
              subItems: matches
                ?.map((match) => ({
                  title: match.title ? match.title : "",
                  href: match.href ? match.href : "",
                }))
                .slice(0, sliceLength),
              collapsed: true,
            },
          ]
        : [],
    };
  }
  if (subCatField === "trid") {
    return {
      title: "TRiD File Type Report",
      items: subCategories[subCatField].filter((item) => item),
    };
  }
  if (subCatField === "die") {
    return {
      title: "DiE File Type Report",
      items: subCategories[subCatField],
    };
  }
};

export const prepareStaticInfoDataStaticResultsTab = (subCatFields, subCategories) => {
  const result = [];

  if (subCatFields.find((subCatField) => subCatField === "clamav")) {
    const items = subCategories["clamav"];
    result.push({
      title: `AV Detection${items?.length > 1 ? "s" : ""}`,
      items,
    });
  } else {
    result.push({
      title: "AV Detection",
      items: [],
    });
  }

  if (subCatFields.find((subCatField) => subCatField === "metadefenfer")) {
    result.push({
      title: "Metadefender Classification",
      items: subCategories["metadefenfer"]?.threat_name
        ? [subCategories["metadefenfer"].threat_name]
        : [],
    });
  } else {
    result.push({
      title: "Metadefender Classification",
      items: [],
    });
  }

  if (subCatFields.find((subCatField) => subCatField === "yara")) {
    result.push({
      title: "All YARA Rule Matches",
      items: subCategories["yara"]
        ?.map((yara) => {
          const subItems = [];
          const obj = {};

          if (yara?.meta?.description)
            obj["description"] = yara.meta.description.replace(/_Weak$/, "");
          if (yara?.meta?.Description)
            obj["description"] = yara.meta.Description.replace(/_Weak$/, "");
          if (yara?.strings?.length) obj["matches"] = yara.strings;

          if (Object.keys(obj).includes("description") || Object.keys(obj).includes("matches"))
            subItems.push(obj);

          return {
            subTitle: yara?.name?.replace(/_Weak$/, ""),
            subItems,
            collapsed: true,
          };
        })
        .filter(
          (yara, idx, arr) => idx === arr.findIndex((item) => item.subTitle === yara.subTitle),
        ),
    });
  } else {
    result.push({
      title: "All YARA Rule Matches",
      items: [],
    });
  }

  if (subCatFields.find((subCatField) => subCatField === "virustotal")) {
    const virusTotalData = subCategories["virustotal"]["popular_threat_classification"];
    const items = [];

    if (virusTotalData) {
      const subTitle = virusTotalData["suggested_threat_label"];
      const obj = {};

      obj["threatCategories"] = virusTotalData["popular_threat_category"]?.map((ptc) => ptc.value);
      obj["familyLabels"] = virusTotalData["popular_threat_name"]?.map((ptn) => ptn.value);
      items.push({ subTitle, subItems: [obj], collapsed: true });
    }

    result.push({
      title: "Virustotal Classification",
      items: items,
    });
  } else {
    result.push({
      title: "Virustotal Classification",
      items: [],
    });
  }

  if (subCatFields.find((subCatField) => subCatField === "safebrowsing")) {
    const matches = subCategories["safebrowsing"]?.matches;
    const sliceLength = matches?.length < 4 ? matches.length : 4;
    result.push({
      title: `Prevalance Match${matches?.length > 1 ? "es" : ""}`,
      items: matches?.length
        ? [
            {
              subTitle: `Match at ${matches.length} page${
                matches.length > 1 ? "s" : ""
              }. Expand to see ${
                matches.length > 1 ? `first ${sliceLength} matches` : "first match"
              }.`,
              subItems: matches
                ?.map((match) => ({
                  title: match.title ? match.title : "",
                  href: match.href ? match.href : "",
                }))
                .slice(0, sliceLength),
              collapsed: true,
            },
          ]
        : [],
    });
  } else {
    result.push({
      title: "Prevalance Match",
      items: [],
    });
  }

  if (subCatFields.find((subCatField) => subCatField === "trid")) {
    result.push({
      title: "TRiD File Type Report",
      items: subCategories["trid"].filter((item) => item),
    });
  } else {
    result.push({
      title: "TRiD File Type Report",
      items: [],
    });
  }

  if (subCatFields.find((subCatField) => subCatField === "die")) {
    result.push({
      title: "DiE File Type Report",
      items: subCategories["die"],
    });
  } else {
    result.push({
      title: "DiE File Type Report",
      items: [],
    });
  }

  return result;
};

// export const addMissingStaticFields = (staticInfoData) => {
//   const newStaticInfoData = [...(staticInfoData ? staticInfoData : [])];
//   if (
//     !newStaticInfoData.some((sinfo) => sinfo.title === "AV Detection") &&
//     !newStaticInfoData.some((sinfo) => sinfo.title === "AV Detections")
//   ) {
//     newStaticInfoData.push({ title: "AV Detection", items: [] });
//   }
//   if (!newStaticInfoData.some((sinfo) => sinfo.title === "Metadefender Classification")) {
//     newStaticInfoData.push({ title: "Metadefender Classification", items: [] });
//   }

//   if (!newStaticInfoData.some((sinfo) => sinfo.title === "All YARA Rule Matches")) {
//     newStaticInfoData.push({ title: "All YARA Rule Matches", items: [] });
//   }

//   if (!newStaticInfoData.some((sinfo) => sinfo.title === "Virustotal Classification")) {
//     newStaticInfoData.push({ title: "Virustotal Classification", items: [] });
//   }

//   if (
//     !newStaticInfoData.some((sinfo) => sinfo.title === "Prevalance Match") &&
//     !newStaticInfoData.some((sinfo) => sinfo.title === "Prevalance Matches")
//   ) {
//     newStaticInfoData.push({ title: "Prevalance Match", items: [] });
//   }

//   if (!newStaticInfoData.some((sinfo) => sinfo.title === "TRiD File Type Report")) {
//     newStaticInfoData.push({ title: "TRiD File Type Report", items: [] });
//   }

//   if (!newStaticInfoData.some((sinfo) => sinfo.title === "DiE File Type Report")) {
//     newStaticInfoData.push({ title: "DiE File Type Report", items: [] });
//   }

//   return newStaticInfoData;
// };
