import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Chart from "../../../common/Chart/Chart";
import {
  RequestFilesInfo,
  RequestAttackMapTree,
  RequestGetReportLogo,
} from "../../../../store/actions/analysisReportActions";
import ClamCloudMl from "../../AnalysisReports/ClamCloudMlResults/ClamCloudMl";
import Overview from "../../../../Pages/AnalysisReports/Content/Tabs/Overview/Overview";
import ProcessesTree from "../ProcessesTree/ProcessesTree";
import Map from "../../../common/Map/Map";
import Matrix from "../../../../Pages/AnalysisReports/Content/Tabs/Matrix/Matrix";
import Classification from "../../AnalysisReports/Classification/Classification";
import ClassCategories from "../ClassCategories/ClassCategories";
import LogoIcon from "../../../Icon-SVG/LogoIcon";
import html2pdf from "html2pdf.js";
import Backdrop from "../../../UI/Backdrop/Backdrop";
import "./styles.scss";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ReportPDF = () => {
  const dispatch = useDispatch();
  let query = useQuery();
  const history = useHistory();
  const middleRef = useRef(null);

  const general = useSelector((state) => state.analysisReports.general);
  const filesInfo = useSelector((state) => state.analysisReports.filesInfo);
  const attackMapTree = useSelector((state) => state.analysisReports.attackMapTree);
  const signatureList = useSelector((state) => state.analysisReports.signatureList);
  const networkActivityMap = useSelector((state) => state.analysisReports.networkActivityMap);
  const reportLogo = useSelector((state) => state.analysisReports.reportLogo);

  const generalTab = query.get("g");
  const classificationTab = query.get("c");
  const signaturesTab = query.get("s");
  const processesTab = query.get("p");
  const networkTab = query.get("n");
  const mitreTab = query.get("m");

  const [tree, setTree] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    dispatch(RequestFilesInfo(query.get("data")));
    dispatch(RequestAttackMapTree(query.get("data")));
    dispatch(RequestGetReportLogo());
  }, [dispatch]);

  useEffect(() => {
    attackMapTree.map((data) => setTree(data.behavior.processtree));
  }, [attackMapTree]);

  const generatePdf = () => {
    const reportContainer = document.querySelector(".reportpdf-main__content");

    const unwantedDivSelectors = [];

    if (generalTab === "false") {
      unwantedDivSelectors.push(".reportpdf-main__content--general");
    }
    if (classificationTab === "false") {
      unwantedDivSelectors.push(".reportpdf-main__content--classification");
    }
    if (signaturesTab === "false") {
      unwantedDivSelectors.push(".reportpdf-main__content--signatures");
    }
    if (processesTab === "false") {
      unwantedDivSelectors.push(".reportpdf-main__content--processes");
    }
    if (networkTab === "false") {
      unwantedDivSelectors.push(".reportpdf-main__content--network");
    }
    if (mitreTab === "false") {
      unwantedDivSelectors.push(".reportpdf-main__content--mitre");
    }

    unwantedDivSelectors.forEach((selector) => {
      const unwantedDivs = reportContainer.querySelectorAll(selector);
      unwantedDivs.forEach((unwantedDiv) => {
        unwantedDiv.remove();
      });
    });

    const options = {
      margin: 10,
      filename: "Report - " + general?.file_name + ".pdf",
      image: { type: "jpeg", quality: 0.5 },
      html2canvas: { dpi: 75, scale: 2, letterRendering: true },
      jsPDF: { unit: "mm", format: "a3", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    const pdfPromise = new Promise((resolve, reject) => {
      html2pdf(reportContainer, options)
        .then((pdf) => {
          console.log("#1", pdf);
          resolve();
        })
        .catch((error) => {
          console.error("Error during PDF generation:", error);
          reject(error);
        });
    });

    pdfPromise.then(() => {
      history.push(`report-export?data=${query.get("data")}&state=${query.get("state")}`);
    });
  };

  useEffect(() => {
    const disableScroll = (e) => {
      e.preventDefault();
    };

    // Kaydırmayı dinleme
    document.body.style.overflow = "hidden";
    document.addEventListener("scroll", disableScroll);

    // Komponentin unmount edildiğinde event listener'ı ve overflow stilini geri al
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("scroll", disableScroll);
    };
  }, []);

  useEffect(() => {
    if (middleRef.current) {
      const middleElement = middleRef.current;
      const middleElementTop = middleElement.offsetTop;
      const middleElementHeight = middleElement.clientHeight;
      const windowHeight = window.innerHeight;

      // Ortadaki elemanın tam ortasına scroll et
      const scrollTo = middleElementTop - (windowHeight - middleElementHeight) / 2;
      window.scrollTo(0, scrollTo);
    }
    const timeoutId = setTimeout(() => {
      if (
        general &&
        filesInfo &&
        attackMapTree &&
        signatureList &&
        tree.length > 0 &&
        networkActivityMap
      ) {
        generatePdf();
      }
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [general, filesInfo, attackMapTree, signatureList, tree, networkActivityMap]);

  return (
    <div className="reportpdf">
      <div className="reportpdf-loading">
        <Backdrop absolute={true} show={true} message="Generating PDF. Please wait..." />
      </div>
      <div className="reportpdf-main">
        <div ref={middleRef} className="reportpdf-main__content">
          <p className="reportpdf-main__content--date">
            {currentDate.toLocaleDateString("tr-TR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
            -cyberfortress©
          </p>
          <img
            alt=""
            id="tableBanner"
            className="reportpdf-main__content--img"
            src={"data:image/png;base64," + reportLogo}
          />
          <p className="reportpdf-main__content--header">Analysis Report</p>
          <div className="reportpdf-main__content--general">
            <h2>General</h2>
            <div className="reportpdf-main__content--general-content">
              <table>
                <tbody>
                  <tr>
                    <td>Target</td>
                    <td>
                      : <span>{general?.file_name}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Size</td>
                    <td>
                      : <span>{general?.size}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>MD5</td>
                    <td>
                      : <span>{filesInfo[0]?.target.file.md5}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>SHA1</td>
                    <td>
                      : <span>{filesInfo[0]?.target.file.sha1}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>SHA256</td>
                    <td>
                      : <span>{general?.sha1}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>SSDEEP</td>
                    <td>
                      : <span>{filesInfo[0]?.target.file.ssdeep}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="reportpdf-main__content--general-content_score">
                <div className="reportpdf-main__content--general-content_score-verdict">
                  <span
                    className={
                      general?.isApt
                        ? "analysis-reports__header-verdict analysis-reports__header-verdict--apt"
                        : query.get("state") === "malicious"
                        ? "analysis-reports__header-verdict analysis-reports__header-verdict--malicious"
                        : query.get("state") === "not suspicious"
                        ? "analysis-reports__header-verdict analysis-reports__header-verdict--not-suspicious"
                        : "analysis-reports__header-verdict analysis-reports__header-verdict--suspicious"
                    }>
                    {general?.isApt ? <span>malicious</span> : query.get("state")}
                  </span>
                </div>
                <div className="reportpdf-main__content--general-content_score-chart analysis-reports__header-doughnutChart">
                  <Chart
                    name={`doughnut-dark`}
                    type="doughnut"
                    fileState={query.get("state")}
                    decision_score={general.decision_score}
                  />
                </div>
                <ClamCloudMl report={true} />
              </div>
            </div>
          </div>
          <div className="reportpdf-main__content--classification">
            <h2>Classification</h2>
            <div className="reportpdf-main__content--classification-content">
              <Classification theme="dark" report={true} />
              <div className="reportpdf-main__content--classification-content_score">
                <ClassCategories />
              </div>
            </div>
          </div>
          <div className="reportpdf-main__content--signatures">
            <h2>Signatures</h2>
            <div className="reportpdf-main__content--signatures-content">
              <Overview report={true} />
            </div>
          </div>
          <div className="reportpdf-main__content--processes">
            <h2>Processes</h2>
            <div className="reportpdf-main__content--processes-content">
              <ProcessesTree data={tree} />
            </div>
          </div>
          <div className="reportpdf-main__content--network">
            <h2>Network</h2>
            <div className="reportpdf-main__content--network-content">
              <Map reportPdf={true} />
            </div>
          </div>
          <div className="reportpdf-main__content--mitre">
            <h2>Mitre</h2>
            <div className="reportpdf-main__content--mitre-content">
              <Matrix report={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPDF;
