import { useSelector } from "react-redux";
import TabHeader from "../../../../../../common/DroppedFileList/components/TabHeader";

const IocsContent = ({ activeMenu, iocs }) => {
  const generalInfo = useSelector((state) => state.analysisReports.general);

  const isDisabled = iocs[activeMenu.id].length === 0;

  const downloadHandler = () => {
    const blob = new Blob([iocs[activeMenu.id].join("\n")], {
      type: "plain/text",
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `CFSBOX_IOC_${activeMenu.header}_${generalInfo?.sha1}.txt`;
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <div className="iocs__content">
      <div className="iocs__content__header">
        <h3>{activeMenu.header}</h3>
        <button className="iocs__content__download" disabled={isDisabled} onClick={downloadHandler}>
          Download
        </button>
      </div>

      <div className="iocs__content__mid">
        <p>{activeMenu.description}</p>
      </div>

      <div className="iocs__content__bottom">
        <div className="iocs__content__table">
          <TabHeader active={true} setActive={() => {}} />
          <div className="iocs__content__list">
            {Object.keys(iocs).length > 0 && iocs[activeMenu.id].length > 0 ? (
              iocs[activeMenu.id]?.map((ioc, i) => (
                <p className="iocs__content__row" key={i}>
                  {ioc}
                </p>
              ))
            ) : (
              <p className="iocs__content__no-data">No data</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IocsContent;
