import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./styles.scss";

const MalwareStats = ({ activeMenu }) => {
  const [items, setItems] = useState([]);

  const malwareNameCounts = useSelector((state) => state.dashboard.malwareNameCounts);
  const integrationMalwareNameCounts = useSelector(
    (state) => state.dashboard.integrationMalwareNameCounts,
  );

  useEffect(() => {
    let activeList = [...malwareNameCounts];
    if (activeMenu === "integrations" || activeMenu === "integrations_daily") {
      activeList = [...integrationMalwareNameCounts];
    }

    const totalCount = activeList.reduce((sum, currentListItem) => {
      return sum + currentListItem.count;
    }, 0);

    setItems(
      activeList.slice(0, 4).map((listItem) => ({
        malwareName: listItem._id,
        percentage: ((listItem.count / totalCount) * 100).toFixed(0),
        count: listItem.count,
      })),
    );
  }, [activeMenu, malwareNameCounts, integrationMalwareNameCounts]);
  return (
    <div className="malwarestats">
      <div className="malwarestats__item-container">
        {items.length >= 1 ? (
          <>
            <div className="malwarestats__numbers-container">
              <span className="malwarestats__item-percent">%{items[0].percentage}</span>
              <span className="malwarestats__item-count">{items[0].count}</span>
            </div>
            <span className="malwarestats__item-name">#{items[0].malwareName}</span>
          </>
        ) : (
          <span className="malwarestats__no-data">-</span>
        )}
      </div>
      <div className="malwarestats__item-container">
        {items.length >= 2 ? (
          <>
            <div className="malwarestats__numbers-container">
              <span className="malwarestats__item-percent">%{items[1].percentage}</span>
              <span className="malwarestats__item-count">{items[1].count}</span>
            </div>

            <span className="malwarestats__item-name">#{items[1].malwareName}</span>
          </>
        ) : (
          <span className="malwarestats__no-data">-</span>
        )}
      </div>
      <div className="malwarestats__item-container">
        {items.length >= 3 ? (
          <>
            <div className="malwarestats__numbers-container">
              <span className="malwarestats__item-percent">%{items[2].percentage}</span>
              <span className="malwarestats__item-count">{items[2].count}</span>
            </div>
            <span className="malwarestats__item-name">#{items[2].malwareName}</span>
          </>
        ) : (
          <span className="malwarestats__no-data">-</span>
        )}
      </div>
      <div className="malwarestats__item-container">
        {items.length >= 4 ? (
          <>
            <div className="malwarestats__numbers-container">
              <span className="malwarestats__item-percent">%{items[3].percentage}</span>
              <span className="malwarestats__item-count">{items[3].count}</span>
            </div>
            <span className="malwarestats__item-name">#{items[3].malwareName}</span>
          </>
        ) : (
          <span className="malwarestats__no-data">-</span>
        )}
      </div>
    </div>
  );
};

export default MalwareStats;
