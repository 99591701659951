import React, { useState } from "react";
import TabButton from "../../../common/TabButton/TabButton";
import DroppedFileList from "../../../common/DroppedFileList/DroppedFileList";
import MemoryForensics from "./Tabs/MemoryForensics/MemoryForensics";
import InterestingExtractedStrings from "./Tabs/InterestingExtractedStrings/InterestingExtractedStrings";
import DemoPopup from "../../../../Components/common/DemoPopup/DemoPopup";
import Comments from "./Tabs/Comments/Comments";
import Iocs from "./Tabs/IOCs/Iocs";

const MemoryBlock = () => {
  const [active, setActive] = useState("Details of Artifacts");
  const [showDelete, setShowDelete] = useState(false);

  return (
    <>
      <div className="block-memory__tabs button-block">
        <TabButton title="Details of Artifacts" onClick={setActive} active={active} />
        <TabButton title="IOCs" onClick={setActive} active={active} />
        <TabButton title="GPT and Comments" onClick={setActive} active={active} />
        {/*<TabButton title="Memory Forensics" onClick={() => setShowDelete(true)} active={active} />
        <TabButton
          title="Interesting extracted strings"
          onClick={() => setShowDelete(true)}
          active={active}
        />*/}
      </div>
      <>
        {active === "Details of Artifacts" ? (
          <DroppedFileList />
        ) : active === "IOCs" ? (
          <Iocs />
        ) : active === "GPT and Comments" ? (
          <Comments />
        ) : active === "Memory Forensics" ? (
          <MemoryForensics />
        ) : (
          <InterestingExtractedStrings />
        )}
      </>
      <DemoPopup
        onClickOk={() => setShowDelete(false)}
        show={showDelete}
        setShow={setShowDelete}
        text="This feature is not available in the demo version."
        article="DEMO"
      />
    </>
  );
};

export default MemoryBlock;
