import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import LoadingIcon from "../../../../Icon-SVG/LoadingIcon";
import Spinner from "../../../../common/Spinner/Spinner";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SeverityStatusStaticPopup = (props) => {
  let query = useQuery();

  const staticResult = props?.row?.original?.staticResult || {};
  const fileID = query.get("data");
  const staticResultRequestState =
    useSelector((state) => state?.analysisReports?.preStaticResults[fileID]) || {};

  return (
    <div
      className={`severity-status severity-status__${
        staticResultRequestState && staticResultRequestState.error
          ? "error"
          : !staticResult
          ? staticResultRequestState && staticResultRequestState.loading
            ? "loading"
            : "none"
          : !Object.hasOwn(staticResult, "decision") || staticResult.decision === 100
          ? "none"
          : staticResult.decision === 0
          ? "success"
          : staticResult.decision === 2
          ? "secondary"
          : "loading"
      }`}>
      {staticResultRequestState && staticResultRequestState.error ? (
        "n/a"
      ) : !staticResult ||
        !Object.hasOwn(staticResult, "decision") ||
        staticResult.decision === 100 ? (
        staticResultRequestState && staticResultRequestState.loading ? (
          <Spinner width={16} height={16} />
        ) : (
          "n/a"
        )
      ) : staticResult.decision === 99 ? (
        <LoadingIcon color={"var(--text-color-card)"} />
      ) : staticResult.decision === 0 ? (
        "Not Suspicious"
      ) : staticResult.decision === 2 ? (
        staticResult.classification ? (
          "#" +
          staticResult.classification[0].charAt(0).toUpperCase() +
          staticResult.classification[0].slice(1)
        ) : (
          "Malicious"
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default SeverityStatusStaticPopup;
