import React from "react";
import { useSelector } from "react-redux";
import { CategoryCheck } from "./CategoryCheck";
import { useLocation } from "react-router-dom";
import "./styles.scss";

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ClassCategories = () => {
  let query = useQuery();
  const signatureList = useSelector((state) => state.analysisReports.signatureList);
  const general = useSelector((state) => state.analysisReports.general);

  const createSignaturesCategoriesArrayWithEveryCategorie = () => {
    const newSignatureArray = [];

    for (const signature of signatureList) {
      for (const categorie of signature.categories) {
        newSignatureArray.push({ categories: categorie, severity: signature.severity });
      }
    }

    return CategoryCheck(newSignatureArray);
  };

  const ITEMS = createSignaturesCategoriesArrayWithEveryCategorie();

  return (
    ITEMS && (
      <div className="classcategories__display">
        {general?.malwareNames?.slice(0, 2).map((malwareName, i) => (
          <div key={i} className="classcategories__border classcategories__border--malicious">
            <span className="classcategories__text">#{malwareName}</span>
          </div>
        ))}
        {ITEMS.map(
          (item, index) =>
            query.get("state") !== "not suspicious" && (
              <div
                key={index}
                className={`classcategories__border classcategories__border--${
                  item.value
                    ? item.value >= 65
                      ? "malicious"
                      : item.value <= 40
                      ? "nothing"
                      : "suspicious"
                    : "nothing"
                } ${index > 3 ? "classcategories__border--below" : ""}`}>
                <span className="classcategories__text">{item.header}</span>
              </div>
            ),
        )}
      </div>
    )
  );
};
export default ClassCategories;
